import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { AddToCartItem } from '@shopback/ui';
import { observer } from 'mobx-react';

import { useProjectStore, useRootStore } from 'store/globalCtx';
import { ROUTES } from 'config/routes';
import CountSelector from 'components/CountSelector';
import { ProductItem } from 'models/product';
import { SelectedAddonsMap } from 'models/product/types';
import {
  ProjectProjectCartModeChoices,
  ProjectProjectViewModeChoices
} from 'generated/graphql';

type Props = {
  product: ProductItem;
  addons?: SelectedAddonsMap;
  inCartView?: boolean;
};

const ProductCartActions: React.FC<Props> = ({
  product,
  addons = {},
  inCartView = false
}: Props) => {
  const cartStore = useRootStore().cartStore;
  const projectStore = useProjectStore();
  const project = projectStore.project;
  const navigate = useNavigate();

  const cartItem =
    cartStore.findItem(product.id, addons) || cartStore.findProduct(product.id);

  const inCart = !!cartItem;

  const hasRequiredAddons = product.hasRequiredAddons;
  const singleMode = project?.cartMode === ProjectProjectCartModeChoices.Single;

  const onAddToCart = () => {
    if (!project) {
      return null;
    }

    if (hasRequiredAddons || singleMode) {
      return navigate(ROUTES.product.make(product.id));
    }

    cartStore.addToCart(product, addons);
  };

  const onCartInc = () => {
    if (hasRequiredAddons || singleMode) {
      return navigate(ROUTES.product.make(product.id));
    }

    cartStore.incCartCount(product, addons);
  };
  const handleActionsClick = React.useCallback<
    React.MouseEventHandler<HTMLDivElement>
  >((e) => {
    e.stopPropagation();
  }, []);

  return (
    <div onClick={handleActionsClick}>
      {!inCart ? (
        <AddToCartItem
          onClick={onAddToCart}
          isSmall={project?.viewMode === ProjectProjectViewModeChoices.Small}
          caption={project?.productInListCallToActionTitle || undefined}
        />
      ) : (
        <CountSelector
          incDisabled={cartItem?.incDisabled}
          value={
            inCartView ? cartItem?.count : cartStore.getProductCount(product.id)
          }
          onInc={onCartInc}
          onDec={() => cartStore.decCartCount(product, addons)}
        />
      )}
    </div>
  );
};

export default observer(ProductCartActions);
