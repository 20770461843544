import * as React from 'react';

const Logo: React.FC = () => {
  return (
    <svg
      fill="none"
      height="60"
      viewBox="0 0 60 60"
      width="60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#fff">
        <path d="m42.5755 32.2959h-8.1429c-2.2285 0-4.0408 1.8122-4.0408 4.0408v6.2449c0 2.2286 1.8123 4.0408 4.0408 4.0408h8.1429c2.2286 0 4.0408-1.8122 4.0408-4.0408v-6.2449c0-2.2286-1.8122-4.0408-4.0408-4.0408zm1.102 10.2857c0 .6123-.502 1.1021-1.102 1.1021h-8.1429c-.6122 0-1.102-.5021-1.102-1.1021v-6.2449c0-.6122.502-1.102 1.102-1.102h8.1429c.6123 0 1.102.502 1.102 1.102z" />
        <path d="m59.9265 20.9449c0-.2572-.0612-.5021-.1959-.7225l-8.9143-15.67346c-.2571-.45306-.7469-.74694-1.2734-.74694h-39.1715c-.5265 0-1.0163.28163-1.27344.74694l-8.902042 15.67346c-.1224486.2204-.195918.4653-.195918.7225 0 3.5143 2.11837 6.5265 5.14286 7.8612v25.849c0 .8082.66122 1.4694 1.46938 1.4694h8.11836.0367 11.3878.0367 27.1102c.8082 0 1.4694-.6612 1.4694-1.4694v-25.6653c0-.0612 0-.1102-.0122-.1592 3.0367-1.3347 5.1673-4.3592 5.1673-7.8857zm-48.6979-14.19188h37.4571l6.9674 12.25718h-51.37963zm31.4204 15.18368c-.4653 2.6449-2.7796 4.6531-5.5592 4.6531s-5.0939-2.0082-5.5592-4.6531zm-14.2408 0c-.4653 2.6449-2.7796 4.6531-5.5592 4.6531s-5.0939-2.0082-5.5714-4.6531zm-25.37147 0h11.13057c-.4653 2.6449-2.7918 4.6531-5.57138 4.6531-2.77959.0122-5.09388-2.0082-5.55919-4.6531zm21.64897 31.249h-8.449v-15.8571c0-1.1511.9306-2.0939 2.0939-2.0939h4.2735c1.151 0 2.0939.9306 2.0939 2.0939v15.8571zm27.147 0h-24.2082v-15.8571c0-2.7674-2.2531-5.0327-5.0327-5.0327h-4.2734c-2.7674 0-5.0327 2.2531-5.0327 5.0327v15.8693h-5.20407v-23.6693c.17143.0122.34286.0122.51429.0122 2.96328 0 5.58368-1.5061 7.12648-3.7959 1.5429 2.2898 4.1633 3.7959 7.1266 3.7959 2.9632 0 5.5714-1.5061 7.1265-3.7959 1.5429 2.2898 4.1633 3.7959 7.1143 3.7959 2.9633 0 5.5714-1.5061 7.1143-3.7959 1.5428 2.2898 4.1632 3.7959 7.1265 3.7959.1714 0 .3306-.0122.5021-.0122zm-.5021-26.5837c-2.7796 0-5.0939-2.0081-5.5592-4.653h11.1306c-.4775 2.6326-2.7918 4.653-5.5714 4.653z" />
      </g>
    </svg>
  );
};

export default Logo;
