import { action, computed, makeObservable, observable } from 'mobx';

import { ProductItemFragment } from 'generated/graphql';
import { CartItemInLS, CartItemToEvent } from 'types/cart';
import { ICartStore } from 'types/cartStore';
import { ProductItem } from 'models/product/ProductItem';
import { SelectedAddonsMap } from 'models/product/types';

export class CartItem extends ProductItem {
  private _count: number;
  readonly selectedAddons: SelectedAddonsMap;

  constructor(
    {
      count,
      selectedAddons,
      ...rest
    }: Omit<ProductItemFragment, 'count' | 'price'> & {
      count: number;
      price: number;
      maxCount: number | null;
      selectedAddons: SelectedAddonsMap;
      categoryId: string | null;
    },
    cartStore: ICartStore
  ) {
    super(rest, cartStore.rootStore.projectStore);

    this._count = count;
    this.selectedAddons = selectedAddons;

    makeObservable<CartItem, '_count'>(this, {
      _count: observable,

      count: computed,
      sum: computed,
      incDisabled: computed,

      inc: action,
      dec: action
    });
  }

  get count(): number {
    return this._count;
  }

  get priceWithAddons(): number {
    const addonsCost = Object.values(this.selectedAddons).reduce(
      (acc, next) =>
        acc +
        Object.values(next.addonOptions).reduce(
          (acc, next) => acc + next.extraCost,
          0
        ),
      0
    );

    return this.price + addonsCost;
  }

  get sum(): number {
    return this.count * this.priceWithAddons;
  }

  get incDisabled(): boolean {
    return this.maxCount !== null && this._count + 1 > this.maxCount;
  }

  inc = (): void => {
    if (this.incDisabled) {
      return;
    }

    this._count++;
  };

  dec = (): void => {
    this._count--;
  };

  toLSFormat(): CartItemInLS {
    return {
      id: this.id,
      count: this.count,
      addons: this.addonsToEvent()
    };
  }

  addonsToEvent(): Record<string, string[]> {
    return Object.keys(this.selectedAddons).reduce(
      (acc, next) => ({
        ...acc,
        [next]: Object.keys(this.selectedAddons[next].addonOptions)
      }),
      {}
    );
  }

  toEvent(): CartItemToEvent {
    return {
      count: this.count,
      product: {
        id: this.id,
        name: this.name,
        price: this.price,
        addons: this.addonsToEvent()
      }
    };
  }

  toCreatingCartFormat() {
    return {
      pid: this.id,
      count: this.count,
      opts: Object.values(this.addonsToEvent()).reduce(
        (acc, next) => [...acc, ...next],
        []
      )
    };
  }

  static fromProduct(
    data: ProductItemFragment & {
      countInCart: number;
      selectedAddons: SelectedAddonsMap;
    },
    cartStore: ICartStore
  ): CartItem {
    return new CartItem(
      {
        ...data,
        price: Number(data.price) || 0,
        count: data.countInCart,
        maxCount: typeof data.count === 'number' ? data.count : null,
        categoryId: data.category?.id || null
      },
      cartStore
    );
  }
}
