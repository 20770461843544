import * as React from 'react';
import { CartPageTemplate } from '@shopback/ui';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import {
  AttachmentType,
  ProductSelectedOptionType,
  ProductType
} from '@shopback/ui/lib/types';

import {
  useCartStore,
  useProjectStore,
  useRootStore,
  useUiStore
} from 'store/globalCtx';
import { ROUTES } from 'config/routes';
import ProductCartActions from 'components/ProductCartActions';

const Cart: React.FC = () => {
  const rootStore = useRootStore();
  const cartStore = useCartStore();
  const projectStore = useProjectStore();
  const project = projectStore.project;
  const navigate = useNavigate();
  const uiStore = useUiStore();

  React.useEffect(() => {
    if (rootStore.isAuthorize) {
      cartStore.loadProducts();
    }
  }, [rootStore.isAuthorize]);

  if (!project) {
    return null;
  }

  const items: ProductType[] = cartStore.items.map((item) => ({
    id: item.id,
    name: item.name,
    description: item.description || undefined,
    price: item.price,
    attachments: item.attachments
      .slice(0, 3)
      .reduce<AttachmentType[]>((acc, attachment) => {
        if (!attachment.smallImage.url) {
          return acc;
        }

        acc.push({
          src: attachment.smallImage.url,
          height: attachment.smallImage.height || undefined,
          width: attachment.smallImage.width || undefined
        });

        return acc;
      }, []),
    onClick: () => navigate(ROUTES.product.make(item.id)),
    actionsRenderProps: () => <ProductCartActions product={item} />,
    options: item.selectedOptions.options?.reduce<ProductSelectedOptionType[]>(
      (acc, selectedOption) => {
        const option = !item.productOptions.options
          ? null
          : item.productOptions.options.find(
              (opt) => opt.id === selectedOption.id
            );

        if (!option) {
          return acc;
        }

        acc.push({
          name: option.name,
          value: selectedOption.value
        });

        return acc;
      },
      []
    )
  }));

  return (
    <CartPageTemplate
      withScrollBar={uiStore.needScrollBar}
      projectCartTitle={project.cartTitle || undefined}
      currency={project.currency || undefined}
      sumPrice={
        !cartStore.isEmpty && cartStore.orderSum > 0
          ? cartStore.orderSum
          : undefined
      }
      cartButton={
        !cartStore.isEmpty
          ? {
              onClick: cartStore.buy,
              projectFinishButtonTitle: project.finishButtonTitle || undefined
            }
          : undefined
      }
      onBackClick={() => navigate(ROUTES.main.index)}
      items={items}
    />
  );
};

export default observer(Cart);
