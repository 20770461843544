import * as React from 'react';

import { Wrapper } from 'components/Wrapper';
import ShopbackLogo from 'components/ShopbackLogo';

const LoadingScreen: React.FC = () => {
  return (
    <Wrapper>
      <ShopbackLogo />
    </Wrapper>
  );
};

export default LoadingScreen;
