import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  DateTime: any;
  Decimal: any;
  GenericScalar: any;
  UUID: any;
};

export type AddProductToOrderMutationInput = {
  count: Scalars['Int'];
  productId: Scalars['Int'];
};

export type AddProductToOrderMutationResponse = {
  __typename?: 'AddProductToOrderMutationResponse';
  errors?: Maybe<Array<Maybe<GqErrorType>>>;
};

export type AddonOptionType = {
  __typename?: 'AddonOptionType';
  addon: AddonType;
  extraCost: Scalars['Int'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type AddonType = {
  __typename?: 'AddonType';
  addonOptions: Array<AddonOptionType>;
  id: Scalars['ID'];
  isRequired: Scalars['Boolean'];
  order: Scalars['Int'];
  products: Array<ProductType>;
  title: Scalars['String'];
  type: ProductAddonTypeChoices;
};

export type BuyProductsMutationInput = {
  blockId: Scalars['String'];
  chatId: Scalars['String'];
};

export type BuyProductsMutationResponse = {
  __typename?: 'BuyProductsMutationResponse';
  errors?: Maybe<Array<Maybe<GqErrorType>>>;
};

/** An enumeration. */
export enum CartOrderStatusChoices {
  /** canceled */
  Cancelled = 'CANCELLED',
  /** new */
  New = 'NEW',
  /** paid */
  Paid = 'PAID',
  /** wait_payment */
  WaitPayment = 'WAIT_PAYMENT'
}

export type CategoryListType = {
  __typename?: 'CategoryListType';
  categories: Array<CategoryType>;
  errors?: Maybe<Array<Maybe<GqErrorType>>>;
};

export type CategoryMutationInput = {
  attachment?: InputMaybe<Scalars['String']>;
  externalId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  parentExternalId?: InputMaybe<Scalars['String']>;
  project: Scalars['String'];
};

export type CategoryMutationResponse = {
  __typename?: 'CategoryMutationResponse';
  data?: Maybe<CategoryType>;
  errors?: Maybe<Array<Maybe<GqErrorType>>>;
};

export type CategoryType = {
  __typename?: 'CategoryType';
  attachment?: Maybe<ImageAttachmentType>;
  childCategories: Array<CategoryType>;
  childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  kids?: Maybe<Array<CategoryType>>;
  name: Scalars['String'];
  order: Scalars['Int'];
  parent?: Maybe<CategoryType>;
  parentIds?: Maybe<Array<Scalars['BigInt']>>;
  products: Array<ProductType>;
  project: ProjectType;
};

export type ClientType = {
  __typename?: 'ClientType';
  channelId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  orderSet: Array<OrderType>;
  project?: Maybe<ProjectType>;
};

export type FieldProblemType = {
  __typename?: 'FieldProblemType';
  errCode: Scalars['String'];
  errDescription: Scalars['String'];
  fieldName: Scalars['String'];
};

export type GqErrorType = {
  __typename?: 'GqErrorType';
  data?: Maybe<Scalars['GenericScalar']>;
  fieldProblems?: Maybe<Array<FieldProblemType>>;
  message: Scalars['String'];
  status: Scalars['String'];
};

export type ImageAttachmentType = {
  __typename?: 'ImageAttachmentType';
  bigImage: ImageType;
  file: Scalars['String'];
  id: Scalars['ID'];
  smallImage: ImageType;
};

export type ImageType = {
  __typename?: 'ImageType';
  height?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type LinkedProductType = {
  __typename?: 'LinkedProductType';
  attachments: Array<ImageAttachmentType>;
  /** можно оставить пустым, тогда товар можно купить бесконечное количество раз */
  count?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  modificators: Array<ProductOptionType>;
  name: Scalars['String'];
  orderproductSet: Array<OrderProductObjectType>;
  orders: Array<OrderType>;
  parent?: Maybe<ProductType>;
  parentLinkedProducts: Array<ProductType>;
  price?: Maybe<Scalars['Decimal']>;
  productAddons: Array<AddonType>;
  productModifications: Array<ProductType>;
  productOptions: Array<ProjectOptionType>;
  productType: ProductProductProductTypeChoices;
};

export type ModificationMutationInput = {
  attachments?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  description?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Decimal']>;
};

export type ModificationMutationResponse = {
  __typename?: 'ModificationMutationResponse';
  data?: Maybe<ProductType>;
  errors?: Maybe<Array<Maybe<GqErrorType>>>;
};

export type ModificatorOptionType = {
  __typename?: 'ModificatorOptionType';
  extraValue?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  value: Scalars['String'];
};

export type ModificatorType = {
  id: Scalars['String'];
  isModified?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addProduct?: Maybe<AddProductToOrderMutationResponse>;
  buyProduct?: Maybe<BuyProductsMutationResponse>;
  category?: Maybe<CategoryMutationResponse>;
  modification?: Maybe<ModificationMutationResponse>;
  product?: Maybe<ProductMutationResponse>;
  productOptions?: Maybe<OptionsMutationResponse>;
  productRelation?: Maybe<ProductRelationResponse>;
  tgAuth?: Maybe<TgAuthResponse>;
};

export type MutationAddProductArgs = {
  input: AddProductToOrderMutationInput;
};

export type MutationBuyProductArgs = {
  input: BuyProductsMutationInput;
};

export type MutationCategoryArgs = {
  input: CategoryMutationInput;
};

export type MutationModificationArgs = {
  input: ModificationMutationInput;
};

export type MutationProductArgs = {
  input: ProductMutationInput;
};

export type MutationProductOptionsArgs = {
  input: OptionsMutationInput;
};

export type MutationProductRelationArgs = {
  input: ProductRelationInput;
};

export type MutationTgAuthArgs = {
  input: TgAuthInput;
};

/** An enumeration. */
export enum OptionTypeEnum {
  Available = 'AVAILABLE',
  Selected = 'SELECTED',
  Unavailable = 'UNAVAILABLE'
}

export type OptionsMutationInput = {
  addonIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  colorOption?: InputMaybe<Scalars['String']>;
  product: Scalars['Int'];
  sizeOption?: InputMaybe<Scalars['String']>;
};

export type OptionsMutationResponse = {
  __typename?: 'OptionsMutationResponse';
  data?: Maybe<ProductType>;
  errors?: Maybe<Array<Maybe<GqErrorType>>>;
};

export type OrderProductObjectType = {
  __typename?: 'OrderProductObjectType';
  count: Scalars['Int'];
  id: Scalars['ID'];
  order: OrderType;
  product: ProductType;
};

export type OrderType = {
  __typename?: 'OrderType';
  client?: Maybe<ClientType>;
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  orderProducts: Array<OrderProductObjectType>;
  products: Array<ProductType>;
  status: CartOrderStatusChoices;
};

/** An enumeration. */
export enum ProductAddonTypeChoices {
  /** multiple */
  Multiple = 'MULTIPLE',
  /** single */
  Single = 'SINGLE'
}

/** An enumeration. */
export enum ProductCategoryChildCategoriesViewModeChoices {
  /** horizontal */
  Horizontal = 'HORIZONTAL',
  /** inherit */
  Inherit = 'INHERIT',
  /** inline */
  Inline = 'INLINE',
  /** photo */
  Photo = 'PHOTO'
}

export type ProductListFiltersType = {
  category?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  search?: InputMaybe<Scalars['String']>;
};

export type ProductListType = {
  __typename?: 'ProductListType';
  count?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<GqErrorType>>>;
  products: Array<ProductType>;
};

export type ProductMutationInput = {
  attachments: Array<InputMaybe<Scalars['Int']>>;
  categoryExternalId?: InputMaybe<Scalars['String']>;
  categoryName: Scalars['String'];
  categoryProject: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price?: InputMaybe<Scalars['Decimal']>;
};

export type ProductMutationResponse = {
  __typename?: 'ProductMutationResponse';
  data?: Maybe<ProductType>;
  errors?: Maybe<Array<Maybe<GqErrorType>>>;
};

export type ProductOptionDataType = {
  __typename?: 'ProductOptionDataType';
  extraValue?: Maybe<Scalars['String']>;
  state: OptionTypeEnum;
  value: Scalars['String'];
};

export type ProductOptionFiltersType = {
  modificators?: InputMaybe<Array<InputMaybe<ModificatorType>>>;
  productId?: InputMaybe<Scalars['Int']>;
};

export type ProductOptionList = {
  __typename?: 'ProductOptionList';
  options?: Maybe<Array<ProductOptionObjectType>>;
};

export type ProductOptionObjectType = {
  __typename?: 'ProductOptionObjectType';
  data?: Maybe<Array<ProductOptionDataType>>;
  id: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type ProductOptionType = {
  __typename?: 'ProductOptionType';
  option?: Maybe<ProjectOptionType>;
  value?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum ProductProductOptionTypeChoices {
  /** color */
  Color = 'COLOR',
  /** string */
  String = 'STRING'
}

/** An enumeration. */
export enum ProductProductProductTypeChoices {
  /** modification */
  Modification = 'MODIFICATION',
  /** product */
  Product = 'PRODUCT'
}

export type ProductRelationInput = {
  isFavourite?: InputMaybe<Scalars['Boolean']>;
  notifyAboutDiscount?: InputMaybe<Scalars['Boolean']>;
  productId: Scalars['Int'];
};

export type ProductRelationResponse = {
  __typename?: 'ProductRelationResponse';
  data?: Maybe<ProductType>;
  errors?: Maybe<Array<Maybe<GqErrorType>>>;
};

export type ProductType = {
  __typename?: 'ProductType';
  addons?: Maybe<Array<AddonType>>;
  attachments: Array<ImageAttachmentType>;
  category?: Maybe<CategoryType>;
  /** можно оставить пустым, тогда товар можно купить бесконечное количество раз */
  count?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  linkedProducts: Array<LinkedProductType>;
  modificators: Array<ProductOptionType>;
  name: Scalars['String'];
  orderproductSet: Array<OrderProductObjectType>;
  orders: Array<OrderType>;
  parent?: Maybe<ProductType>;
  parentLinkedProducts: Array<ProductType>;
  price?: Maybe<Scalars['Decimal']>;
  productAddons: Array<AddonType>;
  productModifications: Array<ProductType>;
  productOptions: ProductOptionList;
  productType: ProductProductProductTypeChoices;
  selectedOptions: SelectedOptionList;
  userRelation?: Maybe<RelationType>;
};

export type ProjectOptionType = {
  __typename?: 'ProjectOptionType';
  /** название характеристики */
  name: Scalars['String'];
  type: ProductProductOptionTypeChoices;
};

/** An enumeration. */
export enum ProjectProjectCartModeChoices {
  /** multiple */
  Multiple = 'MULTIPLE',
  /** one_item */
  OneItem = 'ONE_ITEM',
  /** single */
  Single = 'SINGLE'
}

/** An enumeration. */
export enum ProjectProjectCategoryViewModeChoices {
  /** horizontal */
  Horizontal = 'HORIZONTAL',
  /** inline */
  Inline = 'INLINE',
  /** photo */
  Photo = 'PHOTO'
}

/** An enumeration. */
export enum ProjectProjectViewModeChoices {
  /** large */
  Large = 'LARGE',
  /** medium */
  Medium = 'MEDIUM',
  /** small */
  Small = 'SMALL'
}

export enum ProjectProjectCardImgProportionChoices {
  /** nine_sixteen */
  NineSixteen = 'NINE_SIXTEEN',
  /** four_five */
  FourFive = 'FOUR_FIVE',
  /** one_one */
  OneOne = 'ONE_ONE',
  /** sixteen_nine */
  SixteenNine = 'SIXTEEN_NINE'
}

export type ProjectType = {
  __typename?: 'ProjectType';
  attachmentSet: Array<ImageAttachmentType>;
  cartButtonTitle?: Maybe<Scalars['String']>;
  cartMode: ProjectProjectCartModeChoices;
  cartTitle?: Maybe<Scalars['String']>;
  categories: Array<CategoryType>;
  categoryViewMode: ProjectProjectCategoryViewModeChoices;
  clientSet: Array<ClientType>;
  currency?: Maybe<Scalars['String']>;
  finishButtonTitle?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mainPageTitle?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  productCallToActionTitle?: Maybe<Scalars['String']>;
  productInListCallToActionTitle?: Maybe<Scalars['String']>;
  showCategory: Scalars['Boolean'];
  showSearch: Scalars['Boolean'];
  smartbotProjectId: Scalars['String'];
  supportElectronicGoods: Scalars['Boolean'];
  supportModification: Scalars['Boolean'];
  tgToken: Scalars['String'];
  uuid: Scalars['UUID'];
  viewMode: ProjectProjectViewModeChoices;
  cardImgProportion?: Maybe<ProjectProjectCardImgProportionChoices>;
  alignCards?: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  categoriesList?: Maybe<CategoryListType>;
  order?: Maybe<OrderType>;
  product?: Maybe<ProductType>;
  productList?: Maybe<ProductListType>;
  project?: Maybe<ProjectType>;
};

export type QueryCategoriesListArgs = {
  parent?: InputMaybe<Scalars['Int']>;
};

export type QueryProductArgs = {
  filters?: InputMaybe<ProductOptionFiltersType>;
};

export type QueryProductListArgs = {
  filters?: InputMaybe<ProductListFiltersType>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type RelationType = {
  __typename?: 'RelationType';
  isFavourite: Scalars['Boolean'];
  notifyAboutDiscount: Scalars['Boolean'];
  product: ProductType;
};

export type SelectedOptionList = {
  __typename?: 'SelectedOptionList';
  options?: Maybe<Array<ModificatorOptionType>>;
};

export type TgAuthInput = {
  channelId: Scalars['String'];
  initData: Scalars['String'];
  projectUuid: Scalars['String'];
};

export type TgAuthResponse = {
  __typename?: 'TgAuthResponse';
  data?: Maybe<TgAuthType>;
  errors?: Maybe<Array<Maybe<GqErrorType>>>;
};

export type TgAuthType = {
  __typename?: 'TgAuthType';
  authToken: Scalars['String'];
};

export type ProjectInfoFragment = {
  __typename?: 'ProjectType';
  id: string;
  name: string;
  viewMode: ProjectProjectViewModeChoices;
  showSearch: boolean;
  showCategory: boolean;
  productCallToActionTitle?: string | null;
  productInListCallToActionTitle?: string | null;
  finishButtonTitle?: string | null;
  cartButtonTitle?: string | null;
  cartTitle?: string | null;
  mainPageTitle?: string | null;
  cartMode: ProjectProjectCartModeChoices;
  currency?: string | null;
  categoryViewMode: ProjectProjectCategoryViewModeChoices;
  categories: Array<{
    __typename?: 'CategoryType';
    id: string;
    name: string;
    childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
    childCategories: Array<{
      __typename?: 'CategoryType';
      id: string;
      name: string;
      childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
      childCategories: Array<{
        __typename?: 'CategoryType';
        id: string;
        name: string;
        childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
        childCategories: Array<{
          __typename?: 'CategoryType';
          id: string;
          name: string;
          childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
          childCategories: Array<{
            __typename?: 'CategoryType';
            id: string;
            name: string;
            childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
            childCategories: Array<{
              __typename?: 'CategoryType';
              id: string;
              name: string;
              childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
              childCategories: Array<{
                __typename?: 'CategoryType';
                id: string;
                name: string;
                childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
                childCategories: Array<{
                  __typename?: 'CategoryType';
                  id: string;
                  name: string;
                  childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
                  childCategories: Array<{
                    __typename?: 'CategoryType';
                    id: string;
                    name: string;
                    childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
                    childCategories: Array<{
                      __typename?: 'CategoryType';
                      id: string;
                      name: string;
                      childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
                      childCategories: Array<{
                        __typename?: 'CategoryType';
                        id: string;
                        name: string;
                        childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
                        parent?: {
                          __typename?: 'CategoryType';
                          id: string;
                        } | null;
                        attachment?: {
                          __typename?: 'ImageAttachmentType';
                          file: string;
                          smallImage: {
                            __typename?: 'ImageType';
                            url?: string | null;
                            width?: number | null;
                            height?: number | null;
                          };
                        } | null;
                      }>;
                      parent?: {
                        __typename?: 'CategoryType';
                        id: string;
                      } | null;
                      attachment?: {
                        __typename?: 'ImageAttachmentType';
                        file: string;
                        smallImage: {
                          __typename?: 'ImageType';
                          url?: string | null;
                          width?: number | null;
                          height?: number | null;
                        };
                      } | null;
                    }>;
                    parent?: { __typename?: 'CategoryType'; id: string } | null;
                    attachment?: {
                      __typename?: 'ImageAttachmentType';
                      file: string;
                      smallImage: {
                        __typename?: 'ImageType';
                        url?: string | null;
                        width?: number | null;
                        height?: number | null;
                      };
                    } | null;
                  }>;
                  parent?: { __typename?: 'CategoryType'; id: string } | null;
                  attachment?: {
                    __typename?: 'ImageAttachmentType';
                    file: string;
                    smallImage: {
                      __typename?: 'ImageType';
                      url?: string | null;
                      width?: number | null;
                      height?: number | null;
                    };
                  } | null;
                }>;
                parent?: { __typename?: 'CategoryType'; id: string } | null;
                attachment?: {
                  __typename?: 'ImageAttachmentType';
                  file: string;
                  smallImage: {
                    __typename?: 'ImageType';
                    url?: string | null;
                    width?: number | null;
                    height?: number | null;
                  };
                } | null;
              }>;
              parent?: { __typename?: 'CategoryType'; id: string } | null;
              attachment?: {
                __typename?: 'ImageAttachmentType';
                file: string;
                smallImage: {
                  __typename?: 'ImageType';
                  url?: string | null;
                  width?: number | null;
                  height?: number | null;
                };
              } | null;
            }>;
            parent?: { __typename?: 'CategoryType'; id: string } | null;
            attachment?: {
              __typename?: 'ImageAttachmentType';
              file: string;
              smallImage: {
                __typename?: 'ImageType';
                url?: string | null;
                width?: number | null;
                height?: number | null;
              };
            } | null;
          }>;
          parent?: { __typename?: 'CategoryType'; id: string } | null;
          attachment?: {
            __typename?: 'ImageAttachmentType';
            file: string;
            smallImage: {
              __typename?: 'ImageType';
              url?: string | null;
              width?: number | null;
              height?: number | null;
            };
          } | null;
        }>;
        parent?: { __typename?: 'CategoryType'; id: string } | null;
        attachment?: {
          __typename?: 'ImageAttachmentType';
          file: string;
          smallImage: {
            __typename?: 'ImageType';
            url?: string | null;
            width?: number | null;
            height?: number | null;
          };
        } | null;
      }>;
      parent?: { __typename?: 'CategoryType'; id: string } | null;
      attachment?: {
        __typename?: 'ImageAttachmentType';
        file: string;
        smallImage: {
          __typename?: 'ImageType';
          url?: string | null;
          width?: number | null;
          height?: number | null;
        };
      } | null;
    }>;
    parent?: { __typename?: 'CategoryType'; id: string } | null;
    attachment?: {
      __typename?: 'ImageAttachmentType';
      file: string;
      smallImage: {
        __typename?: 'ImageType';
        url?: string | null;
        width?: number | null;
        height?: number | null;
      };
    } | null;
  }>;
  cardImgProportion?: ProjectProjectCardImgProportionChoices | null;
  alignCards?: boolean;
};

export type ProductOptionBaseFragment = {
  __typename?: 'ProjectOptionType';
  name: string;
  type: ProductProductOptionTypeChoices;
};

export type ProductOptionFragment = {
  __typename?: 'ProductOptionType';
  value?: string | null;
  option?: {
    __typename?: 'ProjectOptionType';
    name: string;
    type: ProductProductOptionTypeChoices;
  } | null;
};

export type ProductOptionDataFragment = {
  __typename?: 'ProductOptionDataType';
  state: OptionTypeEnum;
  value: string;
  extraValue?: string | null;
};

export type ProductOptionObjectBaseFragment = {
  __typename?: 'ProductOptionObjectType';
  id: string;
  name: string;
  type: string;
  data?: Array<{
    __typename?: 'ProductOptionDataType';
    state: OptionTypeEnum;
    value: string;
    extraValue?: string | null;
  }> | null;
};

export type ProductItemDataFragment = {
  __typename?: 'ProductType';
  id: string;
  description?: string | null;
  price?: any | null;
  count?: number | null;
};

export type ModificatorOptionFragment = {
  __typename?: 'ModificatorOptionType';
  id: string;
  value: string;
};

export type SelectedOptionsFragment = {
  __typename?: 'SelectedOptionList';
  options?: Array<{
    __typename?: 'ModificatorOptionType';
    id: string;
    value: string;
  }> | null;
};

export type ProductOptionsFragment = {
  __typename?: 'ProductOptionList';
  options?: Array<{
    __typename?: 'ProductOptionObjectType';
    id: string;
    name: string;
    type: string;
    data?: Array<{
      __typename?: 'ProductOptionDataType';
      state: OptionTypeEnum;
      value: string;
      extraValue?: string | null;
    }> | null;
  }> | null;
};

export type ImageTypeFragment = {
  __typename?: 'ImageType';
  url?: string | null;
  width?: number | null;
  height?: number | null;
};

export type ImageAttachmentFragment = {
  __typename?: 'ImageAttachmentType';
  file: string;
  bigImage: {
    __typename?: 'ImageType';
    url?: string | null;
    width?: number | null;
    height?: number | null;
  };
  smallImage: {
    __typename?: 'ImageType';
    url?: string | null;
    width?: number | null;
    height?: number | null;
  };
};

export type AddonOptionFragment = {
  __typename?: 'AddonOptionType';
  title: string;
  id: string;
  extraCost: number;
};

export type AddonFragment = {
  __typename?: 'AddonType';
  isRequired: boolean;
  type: ProductAddonTypeChoices;
  title: string;
  id: string;
  order: number;
  addonOptions: Array<{
    __typename?: 'AddonOptionType';
    title: string;
    id: string;
    extraCost: number;
  }>;
};

export type OrderProductObjectFragment = {
  __typename?: 'OrderProductObjectType';
  count: number;
  product: {
    __typename?: 'ProductType';
    name: string;
    id: string;
    description?: string | null;
    price?: any | null;
    count?: number | null;
    category?: { __typename?: 'CategoryType'; id: string } | null;
    attachments: Array<{
      __typename?: 'ImageAttachmentType';
      file: string;
      bigImage: {
        __typename?: 'ImageType';
        url?: string | null;
        width?: number | null;
        height?: number | null;
      };
      smallImage: {
        __typename?: 'ImageType';
        url?: string | null;
        width?: number | null;
        height?: number | null;
      };
    }>;
    addons?: Array<{
      __typename?: 'AddonType';
      isRequired: boolean;
      type: ProductAddonTypeChoices;
      title: string;
      id: string;
      order: number;
      addonOptions: Array<{
        __typename?: 'AddonOptionType';
        title: string;
        id: string;
        extraCost: number;
      }>;
    }> | null;
    productOptions: {
      __typename?: 'ProductOptionList';
      options?: Array<{
        __typename?: 'ProductOptionObjectType';
        id: string;
        name: string;
        type: string;
        data?: Array<{
          __typename?: 'ProductOptionDataType';
          state: OptionTypeEnum;
          value: string;
          extraValue?: string | null;
        }> | null;
      }> | null;
    };
    selectedOptions: {
      __typename?: 'SelectedOptionList';
      options?: Array<{
        __typename?: 'ModificatorOptionType';
        id: string;
        value: string;
      }> | null;
    };
  };
};

export type ProductItemFragment = {
  __typename?: 'ProductType';
  name: string;
  id: string;
  description?: string | null;
  price?: any | null;
  count?: number | null;
  category?: { __typename?: 'CategoryType'; id: string } | null;
  attachments: Array<{
    __typename?: 'ImageAttachmentType';
    file: string;
    bigImage: {
      __typename?: 'ImageType';
      url?: string | null;
      width?: number | null;
      height?: number | null;
    };
    smallImage: {
      __typename?: 'ImageType';
      url?: string | null;
      width?: number | null;
      height?: number | null;
    };
  }>;
  addons?: Array<{
    __typename?: 'AddonType';
    isRequired: boolean;
    type: ProductAddonTypeChoices;
    title: string;
    id: string;
    order: number;
    addonOptions: Array<{
      __typename?: 'AddonOptionType';
      title: string;
      id: string;
      extraCost: number;
    }>;
  }> | null;
  productOptions: {
    __typename?: 'ProductOptionList';
    options?: Array<{
      __typename?: 'ProductOptionObjectType';
      id: string;
      name: string;
      type: string;
      data?: Array<{
        __typename?: 'ProductOptionDataType';
        state: OptionTypeEnum;
        value: string;
        extraValue?: string | null;
      }> | null;
    }> | null;
  };
  selectedOptions: {
    __typename?: 'SelectedOptionList';
    options?: Array<{
      __typename?: 'ModificatorOptionType';
      id: string;
      value: string;
    }> | null;
  };
};

export type CategoryFieldsFragment = {
  __typename?: 'CategoryType';
  id: string;
  name: string;
  childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
  parent?: { __typename?: 'CategoryType'; id: string } | null;
  attachment?: {
    __typename?: 'ImageAttachmentType';
    file: string;
    smallImage: {
      __typename?: 'ImageType';
      url?: string | null;
      width?: number | null;
      height?: number | null;
    };
  } | null;
};

export type CategoryInfoFragment = {
  __typename?: 'CategoryType';
  id: string;
  name: string;
  childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
  childCategories: Array<{
    __typename?: 'CategoryType';
    id: string;
    name: string;
    childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
    childCategories: Array<{
      __typename?: 'CategoryType';
      id: string;
      name: string;
      childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
      childCategories: Array<{
        __typename?: 'CategoryType';
        id: string;
        name: string;
        childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
        childCategories: Array<{
          __typename?: 'CategoryType';
          id: string;
          name: string;
          childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
          childCategories: Array<{
            __typename?: 'CategoryType';
            id: string;
            name: string;
            childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
            childCategories: Array<{
              __typename?: 'CategoryType';
              id: string;
              name: string;
              childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
              childCategories: Array<{
                __typename?: 'CategoryType';
                id: string;
                name: string;
                childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
                childCategories: Array<{
                  __typename?: 'CategoryType';
                  id: string;
                  name: string;
                  childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
                  childCategories: Array<{
                    __typename?: 'CategoryType';
                    id: string;
                    name: string;
                    childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
                    childCategories: Array<{
                      __typename?: 'CategoryType';
                      id: string;
                      name: string;
                      childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
                      parent?: {
                        __typename?: 'CategoryType';
                        id: string;
                      } | null;
                      attachment?: {
                        __typename?: 'ImageAttachmentType';
                        file: string;
                        smallImage: {
                          __typename?: 'ImageType';
                          url?: string | null;
                          width?: number | null;
                          height?: number | null;
                        };
                      } | null;
                    }>;
                    parent?: { __typename?: 'CategoryType'; id: string } | null;
                    attachment?: {
                      __typename?: 'ImageAttachmentType';
                      file: string;
                      smallImage: {
                        __typename?: 'ImageType';
                        url?: string | null;
                        width?: number | null;
                        height?: number | null;
                      };
                    } | null;
                  }>;
                  parent?: { __typename?: 'CategoryType'; id: string } | null;
                  attachment?: {
                    __typename?: 'ImageAttachmentType';
                    file: string;
                    smallImage: {
                      __typename?: 'ImageType';
                      url?: string | null;
                      width?: number | null;
                      height?: number | null;
                    };
                  } | null;
                }>;
                parent?: { __typename?: 'CategoryType'; id: string } | null;
                attachment?: {
                  __typename?: 'ImageAttachmentType';
                  file: string;
                  smallImage: {
                    __typename?: 'ImageType';
                    url?: string | null;
                    width?: number | null;
                    height?: number | null;
                  };
                } | null;
              }>;
              parent?: { __typename?: 'CategoryType'; id: string } | null;
              attachment?: {
                __typename?: 'ImageAttachmentType';
                file: string;
                smallImage: {
                  __typename?: 'ImageType';
                  url?: string | null;
                  width?: number | null;
                  height?: number | null;
                };
              } | null;
            }>;
            parent?: { __typename?: 'CategoryType'; id: string } | null;
            attachment?: {
              __typename?: 'ImageAttachmentType';
              file: string;
              smallImage: {
                __typename?: 'ImageType';
                url?: string | null;
                width?: number | null;
                height?: number | null;
              };
            } | null;
          }>;
          parent?: { __typename?: 'CategoryType'; id: string } | null;
          attachment?: {
            __typename?: 'ImageAttachmentType';
            file: string;
            smallImage: {
              __typename?: 'ImageType';
              url?: string | null;
              width?: number | null;
              height?: number | null;
            };
          } | null;
        }>;
        parent?: { __typename?: 'CategoryType'; id: string } | null;
        attachment?: {
          __typename?: 'ImageAttachmentType';
          file: string;
          smallImage: {
            __typename?: 'ImageType';
            url?: string | null;
            width?: number | null;
            height?: number | null;
          };
        } | null;
      }>;
      parent?: { __typename?: 'CategoryType'; id: string } | null;
      attachment?: {
        __typename?: 'ImageAttachmentType';
        file: string;
        smallImage: {
          __typename?: 'ImageType';
          url?: string | null;
          width?: number | null;
          height?: number | null;
        };
      } | null;
    }>;
    parent?: { __typename?: 'CategoryType'; id: string } | null;
    attachment?: {
      __typename?: 'ImageAttachmentType';
      file: string;
      smallImage: {
        __typename?: 'ImageType';
        url?: string | null;
        width?: number | null;
        height?: number | null;
      };
    } | null;
  }>;
  parent?: { __typename?: 'CategoryType'; id: string } | null;
  attachment?: {
    __typename?: 'ImageAttachmentType';
    file: string;
    smallImage: {
      __typename?: 'ImageType';
      url?: string | null;
      width?: number | null;
      height?: number | null;
    };
  } | null;
};

export type GetProductListQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<ProductListFiltersType>;
}>;

export type GetProductListQuery = {
  __typename?: 'Query';
  productList?: {
    __typename?: 'ProductListType';
    count?: number | null;
    products: Array<{
      __typename?: 'ProductType';
      name: string;
      id: string;
      description?: string | null;
      price?: any | null;
      count?: number | null;
      category?: { __typename?: 'CategoryType'; id: string } | null;
      attachments: Array<{
        __typename?: 'ImageAttachmentType';
        file: string;
        bigImage: {
          __typename?: 'ImageType';
          url?: string | null;
          width?: number | null;
          height?: number | null;
        };
        smallImage: {
          __typename?: 'ImageType';
          url?: string | null;
          width?: number | null;
          height?: number | null;
        };
      }>;
      addons?: Array<{
        __typename?: 'AddonType';
        isRequired: boolean;
        type: ProductAddonTypeChoices;
        title: string;
        id: string;
        order: number;
        addonOptions: Array<{
          __typename?: 'AddonOptionType';
          title: string;
          id: string;
          extraCost: number;
        }>;
      }> | null;
      productOptions: {
        __typename?: 'ProductOptionList';
        options?: Array<{
          __typename?: 'ProductOptionObjectType';
          id: string;
          name: string;
          type: string;
          data?: Array<{
            __typename?: 'ProductOptionDataType';
            state: OptionTypeEnum;
            value: string;
            extraValue?: string | null;
          }> | null;
        }> | null;
      };
      selectedOptions: {
        __typename?: 'SelectedOptionList';
        options?: Array<{
          __typename?: 'ModificatorOptionType';
          id: string;
          value: string;
        }> | null;
      };
    }>;
    errors?: Array<{
      __typename?: 'GqErrorType';
      status: string;
      message: string;
    } | null> | null;
  } | null;
};

export type GetProductQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetProductQuery = {
  __typename?: 'Query';
  productList?: {
    __typename?: 'ProductListType';
    count?: number | null;
    products: Array<{
      __typename?: 'ProductType';
      name: string;
      id: string;
      description?: string | null;
      price?: any | null;
      count?: number | null;
      category?: { __typename?: 'CategoryType'; id: string } | null;
      attachments: Array<{
        __typename?: 'ImageAttachmentType';
        file: string;
        bigImage: {
          __typename?: 'ImageType';
          url?: string | null;
          width?: number | null;
          height?: number | null;
        };
        smallImage: {
          __typename?: 'ImageType';
          url?: string | null;
          width?: number | null;
          height?: number | null;
        };
      }>;
      addons?: Array<{
        __typename?: 'AddonType';
        isRequired: boolean;
        type: ProductAddonTypeChoices;
        title: string;
        id: string;
        order: number;
        addonOptions: Array<{
          __typename?: 'AddonOptionType';
          title: string;
          id: string;
          extraCost: number;
        }>;
      }> | null;
      productOptions: {
        __typename?: 'ProductOptionList';
        options?: Array<{
          __typename?: 'ProductOptionObjectType';
          id: string;
          name: string;
          type: string;
          data?: Array<{
            __typename?: 'ProductOptionDataType';
            state: OptionTypeEnum;
            value: string;
            extraValue?: string | null;
          }> | null;
        }> | null;
      };
      selectedOptions: {
        __typename?: 'SelectedOptionList';
        options?: Array<{
          __typename?: 'ModificatorOptionType';
          id: string;
          value: string;
        }> | null;
      };
    }>;
    errors?: Array<{
      __typename?: 'GqErrorType';
      status: string;
      message: string;
    } | null> | null;
  } | null;
};

export type GetProductByModQueryVariables = Exact<{
  modificators?: InputMaybe<
    Array<InputMaybe<ModificatorType>> | InputMaybe<ModificatorType>
  >;
}>;

export type GetProductByModQuery = {
  __typename?: 'Query';
  product?: {
    __typename?: 'ProductType';
    name: string;
    id: string;
    description?: string | null;
    price?: any | null;
    count?: number | null;
    category?: { __typename?: 'CategoryType'; id: string } | null;
    attachments: Array<{
      __typename?: 'ImageAttachmentType';
      file: string;
      bigImage: {
        __typename?: 'ImageType';
        url?: string | null;
        width?: number | null;
        height?: number | null;
      };
      smallImage: {
        __typename?: 'ImageType';
        url?: string | null;
        width?: number | null;
        height?: number | null;
      };
    }>;
    addons?: Array<{
      __typename?: 'AddonType';
      isRequired: boolean;
      type: ProductAddonTypeChoices;
      title: string;
      id: string;
      order: number;
      addonOptions: Array<{
        __typename?: 'AddonOptionType';
        title: string;
        id: string;
        extraCost: number;
      }>;
    }> | null;
    productOptions: {
      __typename?: 'ProductOptionList';
      options?: Array<{
        __typename?: 'ProductOptionObjectType';
        id: string;
        name: string;
        type: string;
        data?: Array<{
          __typename?: 'ProductOptionDataType';
          state: OptionTypeEnum;
          value: string;
          extraValue?: string | null;
        }> | null;
      }> | null;
    };
    selectedOptions: {
      __typename?: 'SelectedOptionList';
      options?: Array<{
        __typename?: 'ModificatorOptionType';
        id: string;
        value: string;
      }> | null;
    };
  } | null;
};

export type TgAuthMutationVariables = Exact<{
  data: TgAuthInput;
}>;

export type TgAuthMutation = {
  __typename?: 'Mutation';
  tgAuth?: {
    __typename?: 'TgAuthResponse';
    data?: { __typename?: 'TgAuthType'; authToken: string } | null;
    errors?: Array<{
      __typename?: 'GqErrorType';
      status: string;
      message: string;
      fieldProblems?: Array<{
        __typename?: 'FieldProblemType';
        fieldName: string;
        errDescription: string;
      }> | null;
    } | null> | null;
  } | null;
};

export type AddProductMutationVariables = Exact<{
  data: AddProductToOrderMutationInput;
}>;

export type AddProductMutation = {
  __typename?: 'Mutation';
  addProduct?: {
    __typename?: 'AddProductToOrderMutationResponse';
    errors?: Array<{
      __typename?: 'GqErrorType';
      status: string;
      message: string;
      fieldProblems?: Array<{
        __typename?: 'FieldProblemType';
        fieldName: string;
        errDescription: string;
      }> | null;
    } | null> | null;
  } | null;
};

export type BuyProductMutationVariables = Exact<{
  data: BuyProductsMutationInput;
}>;

export type BuyProductMutation = {
  __typename?: 'Mutation';
  buyProduct?: {
    __typename?: 'BuyProductsMutationResponse';
    errors?: Array<{
      __typename?: 'GqErrorType';
      status: string;
      message: string;
      fieldProblems?: Array<{
        __typename?: 'FieldProblemType';
        fieldName: string;
        errDescription: string;
      }> | null;
    } | null> | null;
  } | null;
};

export type GetOrderQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrderQuery = {
  __typename?: 'Query';
  order?: {
    __typename?: 'OrderType';
    orderProducts: Array<{
      __typename?: 'OrderProductObjectType';
      count: number;
      product: {
        __typename?: 'ProductType';
        name: string;
        id: string;
        description?: string | null;
        price?: any | null;
        count?: number | null;
        category?: { __typename?: 'CategoryType'; id: string } | null;
        attachments: Array<{
          __typename?: 'ImageAttachmentType';
          file: string;
          bigImage: {
            __typename?: 'ImageType';
            url?: string | null;
            width?: number | null;
            height?: number | null;
          };
          smallImage: {
            __typename?: 'ImageType';
            url?: string | null;
            width?: number | null;
            height?: number | null;
          };
        }>;
        addons?: Array<{
          __typename?: 'AddonType';
          isRequired: boolean;
          type: ProductAddonTypeChoices;
          title: string;
          id: string;
          order: number;
          addonOptions: Array<{
            __typename?: 'AddonOptionType';
            title: string;
            id: string;
            extraCost: number;
          }>;
        }> | null;
        productOptions: {
          __typename?: 'ProductOptionList';
          options?: Array<{
            __typename?: 'ProductOptionObjectType';
            id: string;
            name: string;
            type: string;
            data?: Array<{
              __typename?: 'ProductOptionDataType';
              state: OptionTypeEnum;
              value: string;
              extraValue?: string | null;
            }> | null;
          }> | null;
        };
        selectedOptions: {
          __typename?: 'SelectedOptionList';
          options?: Array<{
            __typename?: 'ModificatorOptionType';
            id: string;
            value: string;
          }> | null;
        };
      };
    }>;
  } | null;
};

export type GetProjectQueryVariables = Exact<{ [key: string]: never }>;

export type GetProjectQuery = {
  __typename?: 'Query';
  project?: {
    __typename?: 'ProjectType';
    id: string;
    name: string;
    viewMode: ProjectProjectViewModeChoices;
    showSearch: boolean;
    showCategory: boolean;
    productCallToActionTitle?: string | null;
    productInListCallToActionTitle?: string | null;
    finishButtonTitle?: string | null;
    cartButtonTitle?: string | null;
    cartTitle?: string | null;
    mainPageTitle?: string | null;
    cartMode: ProjectProjectCartModeChoices;
    currency?: string | null;
    categoryViewMode: ProjectProjectCategoryViewModeChoices;
    categories: Array<{
      __typename?: 'CategoryType';
      id: string;
      name: string;
      childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
      childCategories: Array<{
        __typename?: 'CategoryType';
        id: string;
        name: string;
        childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
        childCategories: Array<{
          __typename?: 'CategoryType';
          id: string;
          name: string;
          childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
          childCategories: Array<{
            __typename?: 'CategoryType';
            id: string;
            name: string;
            childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
            childCategories: Array<{
              __typename?: 'CategoryType';
              id: string;
              name: string;
              childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
              childCategories: Array<{
                __typename?: 'CategoryType';
                id: string;
                name: string;
                childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
                childCategories: Array<{
                  __typename?: 'CategoryType';
                  id: string;
                  name: string;
                  childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
                  childCategories: Array<{
                    __typename?: 'CategoryType';
                    id: string;
                    name: string;
                    childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
                    childCategories: Array<{
                      __typename?: 'CategoryType';
                      id: string;
                      name: string;
                      childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
                      childCategories: Array<{
                        __typename?: 'CategoryType';
                        id: string;
                        name: string;
                        childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
                        childCategories: Array<{
                          __typename?: 'CategoryType';
                          id: string;
                          name: string;
                          childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
                          parent?: {
                            __typename?: 'CategoryType';
                            id: string;
                          } | null;
                          attachment?: {
                            __typename?: 'ImageAttachmentType';
                            file: string;
                            smallImage: {
                              __typename?: 'ImageType';
                              url?: string | null;
                              width?: number | null;
                              height?: number | null;
                            };
                          } | null;
                        }>;
                        parent?: {
                          __typename?: 'CategoryType';
                          id: string;
                        } | null;
                        attachment?: {
                          __typename?: 'ImageAttachmentType';
                          file: string;
                          smallImage: {
                            __typename?: 'ImageType';
                            url?: string | null;
                            width?: number | null;
                            height?: number | null;
                          };
                        } | null;
                      }>;
                      parent?: {
                        __typename?: 'CategoryType';
                        id: string;
                      } | null;
                      attachment?: {
                        __typename?: 'ImageAttachmentType';
                        file: string;
                        smallImage: {
                          __typename?: 'ImageType';
                          url?: string | null;
                          width?: number | null;
                          height?: number | null;
                        };
                      } | null;
                    }>;
                    parent?: { __typename?: 'CategoryType'; id: string } | null;
                    attachment?: {
                      __typename?: 'ImageAttachmentType';
                      file: string;
                      smallImage: {
                        __typename?: 'ImageType';
                        url?: string | null;
                        width?: number | null;
                        height?: number | null;
                      };
                    } | null;
                  }>;
                  parent?: { __typename?: 'CategoryType'; id: string } | null;
                  attachment?: {
                    __typename?: 'ImageAttachmentType';
                    file: string;
                    smallImage: {
                      __typename?: 'ImageType';
                      url?: string | null;
                      width?: number | null;
                      height?: number | null;
                    };
                  } | null;
                }>;
                parent?: { __typename?: 'CategoryType'; id: string } | null;
                attachment?: {
                  __typename?: 'ImageAttachmentType';
                  file: string;
                  smallImage: {
                    __typename?: 'ImageType';
                    url?: string | null;
                    width?: number | null;
                    height?: number | null;
                  };
                } | null;
              }>;
              parent?: { __typename?: 'CategoryType'; id: string } | null;
              attachment?: {
                __typename?: 'ImageAttachmentType';
                file: string;
                smallImage: {
                  __typename?: 'ImageType';
                  url?: string | null;
                  width?: number | null;
                  height?: number | null;
                };
              } | null;
            }>;
            parent?: { __typename?: 'CategoryType'; id: string } | null;
            attachment?: {
              __typename?: 'ImageAttachmentType';
              file: string;
              smallImage: {
                __typename?: 'ImageType';
                url?: string | null;
                width?: number | null;
                height?: number | null;
              };
            } | null;
          }>;
          parent?: { __typename?: 'CategoryType'; id: string } | null;
          attachment?: {
            __typename?: 'ImageAttachmentType';
            file: string;
            smallImage: {
              __typename?: 'ImageType';
              url?: string | null;
              width?: number | null;
              height?: number | null;
            };
          } | null;
        }>;
        parent?: { __typename?: 'CategoryType'; id: string } | null;
        attachment?: {
          __typename?: 'ImageAttachmentType';
          file: string;
          smallImage: {
            __typename?: 'ImageType';
            url?: string | null;
            width?: number | null;
            height?: number | null;
          };
        } | null;
      }>;
      parent?: { __typename?: 'CategoryType'; id: string } | null;
      attachment?: {
        __typename?: 'ImageAttachmentType';
        file: string;
        smallImage: {
          __typename?: 'ImageType';
          url?: string | null;
          width?: number | null;
          height?: number | null;
        };
      } | null;
    }>;
    cardImgProportion?: ProjectProjectCardImgProportionChoices | null;
    alignCards?: boolean;
  } | null;
};

export const ImageTypeFragmentDoc = gql`
  fragment ImageType on ImageType {
    url
    width
    height
  }
`;
export const CategoryFieldsFragmentDoc = gql`
  fragment CategoryFields on CategoryType {
    id
    name
    childCategoriesViewMode
    parent {
      id
    }
    attachment {
      file
      smallImage {
        ...ImageType
      }
    }
  }
  ${ImageTypeFragmentDoc}
`;
export const CategoryInfoFragmentDoc = gql`
  fragment CategoryInfo on CategoryType {
    ...CategoryFields
    childCategories {
      ...CategoryFields
      childCategories {
        ...CategoryFields
        childCategories {
          ...CategoryFields
          childCategories {
            ...CategoryFields
            childCategories {
              ...CategoryFields
              childCategories {
                ...CategoryFields
                childCategories {
                  ...CategoryFields
                  childCategories {
                    ...CategoryFields
                    childCategories {
                      ...CategoryFields
                      childCategories {
                        ...CategoryFields
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${CategoryFieldsFragmentDoc}
`;
export const ProjectInfoFragmentDoc = gql`
  fragment ProjectInfo on ProjectType {
    id
    name
    viewMode
    showSearch
    showCategory
    productCallToActionTitle
    productInListCallToActionTitle
    finishButtonTitle
    cartButtonTitle
    cartTitle
    mainPageTitle
    cartMode
    currency
    categoryViewMode
    cardImgProportion
    alignCards
    categories {
      ...CategoryInfo
    }
  }
  ${CategoryInfoFragmentDoc}
`;
export const ProductOptionBaseFragmentDoc = gql`
  fragment ProductOptionBase on ProjectOptionType {
    name
    type
  }
`;
export const ProductOptionFragmentDoc = gql`
  fragment ProductOption on ProductOptionType {
    value
    option {
      ...ProductOptionBase
    }
  }
  ${ProductOptionBaseFragmentDoc}
`;
export const ImageAttachmentFragmentDoc = gql`
  fragment ImageAttachment on ImageAttachmentType {
    file
    bigImage {
      ...ImageType
    }
    smallImage {
      ...ImageType
    }
  }
  ${ImageTypeFragmentDoc}
`;
export const ProductItemDataFragmentDoc = gql`
  fragment ProductItemData on ProductType {
    id
    description
    price
    count
  }
`;
export const AddonOptionFragmentDoc = gql`
  fragment AddonOption on AddonOptionType {
    title
    id
    extraCost
  }
`;
export const AddonFragmentDoc = gql`
  fragment Addon on AddonType {
    addonOptions {
      ...AddonOption
    }
    isRequired
    type
    title
    id
    order
  }
  ${AddonOptionFragmentDoc}
`;
export const ProductOptionDataFragmentDoc = gql`
  fragment ProductOptionData on ProductOptionDataType {
    state
    value
    extraValue
  }
`;
export const ProductOptionObjectBaseFragmentDoc = gql`
  fragment ProductOptionObjectBase on ProductOptionObjectType {
    data {
      ...ProductOptionData
    }
    id
    name
    type
  }
  ${ProductOptionDataFragmentDoc}
`;
export const ProductOptionsFragmentDoc = gql`
  fragment ProductOptions on ProductOptionList {
    options {
      ...ProductOptionObjectBase
    }
  }
  ${ProductOptionObjectBaseFragmentDoc}
`;
export const ModificatorOptionFragmentDoc = gql`
  fragment ModificatorOption on ModificatorOptionType {
    id
    value
  }
`;
export const SelectedOptionsFragmentDoc = gql`
  fragment SelectedOptions on SelectedOptionList {
    options {
      ...ModificatorOption
    }
  }
  ${ModificatorOptionFragmentDoc}
`;
export const ProductItemFragmentDoc = gql`
  fragment ProductItem on ProductType {
    category {
      id
    }
    name
    attachments {
      ...ImageAttachment
    }
    ...ProductItemData
    addons {
      ...Addon
    }
    productOptions {
      ...ProductOptions
    }
    selectedOptions {
      ...SelectedOptions
    }
  }
  ${ImageAttachmentFragmentDoc}
  ${ProductItemDataFragmentDoc}
  ${AddonFragmentDoc}
  ${ProductOptionsFragmentDoc}
  ${SelectedOptionsFragmentDoc}
`;
export const OrderProductObjectFragmentDoc = gql`
  fragment OrderProductObject on OrderProductObjectType {
    count
    product {
      ...ProductItem
    }
  }
  ${ProductItemFragmentDoc}
`;
export const GetProductListDocument = gql`
  query getProductList(
    $offset: Int
    $limit: Int
    $filters: ProductListFiltersType
  ) {
    productList(limit: $limit, filters: $filters, offset: $offset) {
      count
      products {
        ...ProductItem
      }
      errors {
        status
        message
      }
    }
  }
  ${ProductItemFragmentDoc}
`;

/**
 * __useGetProductListQuery__
 *
 * To run a query within a React component, call `useGetProductListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductListQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetProductListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProductListQuery,
    GetProductListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductListQuery, GetProductListQueryVariables>(
    GetProductListDocument,
    options
  );
}
export function useGetProductListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductListQuery,
    GetProductListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductListQuery, GetProductListQueryVariables>(
    GetProductListDocument,
    options
  );
}
export type GetProductListQueryHookResult = ReturnType<
  typeof useGetProductListQuery
>;
export type GetProductListLazyQueryHookResult = ReturnType<
  typeof useGetProductListLazyQuery
>;
export type GetProductListQueryResult = Apollo.QueryResult<
  GetProductListQuery,
  GetProductListQueryVariables
>;
export const GetProductDocument = gql`
  query getProduct($id: Int!) {
    productList(filters: { ids: [$id] }) {
      count
      products {
        ...ProductItem
      }
      errors {
        status
        message
      }
    }
  }
  ${ProductItemFragmentDoc}
`;

/**
 * __useGetProductQuery__
 *
 * To run a query within a React component, call `useGetProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductQuery,
    GetProductQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductQuery, GetProductQueryVariables>(
    GetProductDocument,
    options
  );
}
export function useGetProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductQuery,
    GetProductQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductQuery, GetProductQueryVariables>(
    GetProductDocument,
    options
  );
}
export type GetProductQueryHookResult = ReturnType<typeof useGetProductQuery>;
export type GetProductLazyQueryHookResult = ReturnType<
  typeof useGetProductLazyQuery
>;
export type GetProductQueryResult = Apollo.QueryResult<
  GetProductQuery,
  GetProductQueryVariables
>;
export const GetProductByModDocument = gql`
  query getProductByMod($modificators: [ModificatorType]) {
    product(filters: { modificators: $modificators }) {
      ...ProductItem
    }
  }
  ${ProductItemFragmentDoc}
`;

/**
 * __useGetProductByModQuery__
 *
 * To run a query within a React component, call `useGetProductByModQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductByModQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductByModQuery({
 *   variables: {
 *      modificators: // value for 'modificators'
 *   },
 * });
 */
export function useGetProductByModQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProductByModQuery,
    GetProductByModQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductByModQuery, GetProductByModQueryVariables>(
    GetProductByModDocument,
    options
  );
}
export function useGetProductByModLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductByModQuery,
    GetProductByModQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductByModQuery,
    GetProductByModQueryVariables
  >(GetProductByModDocument, options);
}
export type GetProductByModQueryHookResult = ReturnType<
  typeof useGetProductByModQuery
>;
export type GetProductByModLazyQueryHookResult = ReturnType<
  typeof useGetProductByModLazyQuery
>;
export type GetProductByModQueryResult = Apollo.QueryResult<
  GetProductByModQuery,
  GetProductByModQueryVariables
>;
export const TgAuthDocument = gql`
  mutation tgAuth($data: TgAuthInput!) {
    tgAuth(input: $data) {
      data {
        authToken
      }
      errors {
        status
        message
        fieldProblems {
          fieldName
          errDescription
        }
      }
    }
  }
`;
export type TgAuthMutationFn = Apollo.MutationFunction<
  TgAuthMutation,
  TgAuthMutationVariables
>;

/**
 * __useTgAuthMutation__
 *
 * To run a mutation, you first call `useTgAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTgAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tgAuthMutation, { data, loading, error }] = useTgAuthMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTgAuthMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TgAuthMutation,
    TgAuthMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TgAuthMutation, TgAuthMutationVariables>(
    TgAuthDocument,
    options
  );
}
export type TgAuthMutationHookResult = ReturnType<typeof useTgAuthMutation>;
export type TgAuthMutationResult = Apollo.MutationResult<TgAuthMutation>;
export type TgAuthMutationOptions = Apollo.BaseMutationOptions<
  TgAuthMutation,
  TgAuthMutationVariables
>;
export const AddProductDocument = gql`
  mutation addProduct($data: AddProductToOrderMutationInput!) {
    addProduct(input: $data) {
      errors {
        status
        message
        fieldProblems {
          fieldName
          errDescription
        }
      }
    }
  }
`;
export type AddProductMutationFn = Apollo.MutationFunction<
  AddProductMutation,
  AddProductMutationVariables
>;

/**
 * __useAddProductMutation__
 *
 * To run a mutation, you first call `useAddProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductMutation, { data, loading, error }] = useAddProductMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddProductMutation,
    AddProductMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddProductMutation, AddProductMutationVariables>(
    AddProductDocument,
    options
  );
}
export type AddProductMutationHookResult = ReturnType<
  typeof useAddProductMutation
>;
export type AddProductMutationResult =
  Apollo.MutationResult<AddProductMutation>;
export type AddProductMutationOptions = Apollo.BaseMutationOptions<
  AddProductMutation,
  AddProductMutationVariables
>;
export const BuyProductDocument = gql`
  mutation buyProduct($data: BuyProductsMutationInput!) {
    buyProduct(input: $data) {
      errors {
        status
        message
        fieldProblems {
          fieldName
          errDescription
        }
      }
    }
  }
`;
export type BuyProductMutationFn = Apollo.MutationFunction<
  BuyProductMutation,
  BuyProductMutationVariables
>;

/**
 * __useBuyProductMutation__
 *
 * To run a mutation, you first call `useBuyProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuyProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buyProductMutation, { data, loading, error }] = useBuyProductMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useBuyProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BuyProductMutation,
    BuyProductMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuyProductMutation, BuyProductMutationVariables>(
    BuyProductDocument,
    options
  );
}
export type BuyProductMutationHookResult = ReturnType<
  typeof useBuyProductMutation
>;
export type BuyProductMutationResult =
  Apollo.MutationResult<BuyProductMutation>;
export type BuyProductMutationOptions = Apollo.BaseMutationOptions<
  BuyProductMutation,
  BuyProductMutationVariables
>;
export const GetOrderDocument = gql`
  query getOrder {
    order {
      orderProducts {
        ...OrderProductObject
      }
    }
  }
  ${OrderProductObjectFragmentDoc}
`;

/**
 * __useGetOrderQuery__
 *
 * To run a query within a React component, call `useGetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrderQuery(
  baseOptions?: Apollo.QueryHookOptions<GetOrderQuery, GetOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrderQuery, GetOrderQueryVariables>(
    GetOrderDocument,
    options
  );
}
export function useGetOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrderQuery,
    GetOrderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrderQuery, GetOrderQueryVariables>(
    GetOrderDocument,
    options
  );
}
export type GetOrderQueryHookResult = ReturnType<typeof useGetOrderQuery>;
export type GetOrderLazyQueryHookResult = ReturnType<
  typeof useGetOrderLazyQuery
>;
export type GetOrderQueryResult = Apollo.QueryResult<
  GetOrderQuery,
  GetOrderQueryVariables
>;
export const GetProjectDocument = gql`
  query getProject {
    project {
      categories {
        ...CategoryInfo
      }
      ...ProjectInfo
    }
  }
  ${CategoryInfoFragmentDoc}
  ${ProjectInfoFragmentDoc}
`;

/**
 * __useGetProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProjectQuery,
    GetProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectQuery, GetProjectQueryVariables>(
    GetProjectDocument,
    options
  );
}
export function useGetProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectQuery,
    GetProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectQuery, GetProjectQueryVariables>(
    GetProjectDocument,
    options
  );
}
export type GetProjectQueryHookResult = ReturnType<typeof useGetProjectQuery>;
export type GetProjectLazyQueryHookResult = ReturnType<
  typeof useGetProjectLazyQuery
>;
export type GetProjectQueryResult = Apollo.QueryResult<
  GetProjectQuery,
  GetProjectQueryVariables
>;
