import styled from 'styled-components';
import { mixins, config } from '@shopback/ui';

export const Wrapper = styled.div`
  ${mixins.square('100%')}
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${config.COLORS.white};
`;
