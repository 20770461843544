import * as React from 'react';

import { Container } from './styles';
import Logo from './Logo';

const ShopbackLogo: React.FC = () => {
  return (
    <Container
      initial={{ scale: 0, rotate: 180 }}
      animate={{ rotate: 0, scale: 1 }}
      transition={{
        type: 'spring',
        stiffness: 260,
        damping: 20
      }}
    >
      <Logo />
    </Container>
  );
};

export default ShopbackLogo;
