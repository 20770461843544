import { LoadingStageModel } from 'models/loadingStage';
import { BaseResponse } from 'types/meta';
import {
  TgAuthDocument,
  TgAuthMutation,
  TgAuthMutationVariables
} from 'generated/graphql';
import { IRootStore } from 'types/rootStore';

export class AuthStore {
  readonly authStage: LoadingStageModel = new LoadingStageModel();
  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
  }

  async authorize(): Promise<BaseResponse<string>> {
    if (this.authStage.isLoading || !this.rootStore.modeData) {
      return {
        isError: true
      };
    }

    this.authStage.loading();

    const { data } = await this.rootStore.apolloClient.mutate<
      TgAuthMutation,
      TgAuthMutationVariables
    >({
      mutation: TgAuthDocument,
      variables: {
        data: {
          initData: this.rootStore.modeData.webAppData,
          channelId: this.rootStore.modeData.channelId,
          projectUuid: this.rootStore.modeData.projectId
        }
      }
    });

    if (!data || !data.tgAuth || !data.tgAuth.data) {
      this.authStage.error();
      return {
        isError: true
      };
    } else {
      this.authStage.success();
      return {
        isError: false,
        data: data.tgAuth.data.authToken
      };
    }
  }
}
