import './init';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'rsuite/dist/rsuite.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { initSentry } from 'scripts';

import App from './App';

import './index.css';

initSentry();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
