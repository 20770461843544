import { action, makeObservable, observable } from 'mobx';

import { ValueModel } from 'models/value';
import { ProductItem } from 'models/product';
import { BaseResponse } from 'types/meta';
import { LoadingStageModel } from 'models/loadingStage';
import { IRootStore } from 'types/rootStore';
import {
  AddonFragment,
  AddonOptionFragment,
  GetProductByModDocument,
  GetProductByModQuery,
  GetProductByModQueryVariables,
  GetProductDocument,
  GetProductQuery,
  GetProductQueryVariables,
  ModificatorType,
  ProductAddonTypeChoices
} from 'generated/graphql';
import { SelectedAddonDataType, SelectedAddonsMap } from 'models/product/types';

export class ProductPageStore {
  readonly product: ValueModel<ProductItem | null> =
    new ValueModel<ProductItem | null>(null);
  readonly loadingStage: LoadingStageModel = new LoadingStageModel();
  readonly loadingByModStage: LoadingStageModel = new LoadingStageModel();
  readonly selectedAddons: SelectedAddonsMap = {};

  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      selectedAddons: observable,
      toggleOption: action
    });
  }

  toggleOption = (addon: AddonFragment, option: AddonOptionFragment) => {
    // Если есть опция, дропаем ее
    if (this.checkOption(addon, option)) {
      delete this.selectedAddons[addon.id].addonOptions[option.id];
      if (
        Object.keys(this.selectedAddons[addon.id].addonOptions).length === 0
      ) {
        delete this.selectedAddons[addon.id];
      }
      return;
    }

    const selectedAddon = this.selectedAddons[addon.id] || {
      addon,
      addonOptions: {}
    };

    if (selectedAddon.addon.type === ProductAddonTypeChoices.Single) {
      selectedAddon.addonOptions = { [option.id]: option };
    } else {
      selectedAddon.addonOptions[option.id] = option;
    }

    this.selectedAddons[addon.id] = selectedAddon;
  };

  checkOption = (addon: AddonFragment, option: AddonOptionFragment) => {
    return Boolean(this.selectedAddons[addon.id]?.addonOptions[option.id]);
  };

  checkAddon = (addon: AddonFragment) => {
    return Boolean(this.selectedAddons[addon.id]);
  };

  get addonsList(): SelectedAddonDataType[] {
    return Object.values(this.selectedAddons);
  }

  get isRequiredAddonsSelected() {
    if (!this.product.value?.productAddons) {
      return true;
    }

    const selectedAddonsIds = Object.keys(this.selectedAddons);

    return this.product.value?.productAddons
      ?.filter((addon) => addon.isRequired)
      .every((a) => selectedAddonsIds.indexOf(a.id) > -1);
  }

  //загрузка продукта по id со всеми характеристиками
  async load(id: number): Promise<BaseResponse> {
    if (
      this.loadingStage.isLoading ||
      this.loadingByModStage.successfullyLoaded
    ) {
      return {
        isError: true
      };
    }

    this.loadingStage.loading();

    const { data } = await this.rootStore.apolloClient.query<
      GetProductQuery,
      GetProductQueryVariables
    >({
      query: GetProductDocument,
      variables: {
        id
      }
    });

    const product = data.productList?.products?.[0];

    if (!product) {
      this.loadingStage.error();
      return {
        isError: true
      };
    }

    this.product.change(
      ProductItem.fromJson(product, this.rootStore.projectStore)
    );
    this.loadingStage.success();

    return {
      isError: false
    };
  }

  async loadProductByMod(
    option: ModificatorType
  ): Promise<BaseResponse<string>> {
    if (this.loadingByModStage.isLoading) {
      return {
        isError: true
      };
    }

    this.loadingByModStage.loading();

    const newOption = {
      id: option.id,
      value: option.value,
      isModified: true
    };

    const modificators: ModificatorType[] =
      this.product.value?.selectedOptions.options?.map((tmpOption) => {
        if (tmpOption.id === option.id) {
          return newOption;
        }
        return {
          id: tmpOption.id,
          value: tmpOption.value
        };
      }) || [newOption];

    const response = await this.rootStore.apolloClient.query<
      GetProductByModQuery,
      GetProductByModQueryVariables
    >({
      query: GetProductByModDocument,
      variables: {
        modificators
      }
    });

    const product = response.data.product;

    if (!product) {
      this.loadingByModStage.error();
      return {
        isError: true
      };
    }

    this.product.change(
      ProductItem.fromJson(product, this.rootStore.projectStore)
    );
    this.loadingByModStage.success();

    return {
      isError: false,
      data: product.id
    };
  }
}
