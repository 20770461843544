import { action, computed, makeObservable, observable } from 'mobx';

export class ValueModel<T = string> {
  protected _value: T;
  private _touched = false;
  protected initialValue: T;

  constructor(value: T) {
    this._value = value;
    this.initialValue = value;

    makeObservable<ValueModel<T>, '_value' | '_touched'>(this, {
      _value: observable,
      _touched: observable,

      value: computed,
      touched: computed,

      change: action,
      resetTouched: action.bound,
      setTouched: action.bound
    });
  }

  get value(): T {
    return this._value;
  }

  change = (value: T): void => {
    this._value = value;
    this._value === this.initialValue ? this.resetTouched() : this.setTouched();
  };

  getCopy(): ValueModel<T> {
    return new ValueModel<T>(this.value);
  }

  isEqual(model: ValueModel<T>): boolean {
    return this.value === model.value;
  }

  get touched(): boolean {
    return this._touched;
  }
  setTouched(): void {
    this._touched = true;
  }

  resetTouched(): void {
    this._touched = false;
  }

  reset(): void {
    this.resetTouched();
    this._value = this.initialValue;
  }
}
