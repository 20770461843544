import { action, computed, makeObservable, observable } from 'mobx';
import { ProjectCardImgProportion } from '@shopback/ui';

import { LoadingStageModel } from 'models/loadingStage';
import { BaseResponse } from 'types/meta';
import {
  CategoryFieldsFragment,
  GetProjectDocument,
  GetProjectQuery,
  ProjectInfoFragment
} from 'generated/graphql';
import { IRootStore } from 'types/rootStore';
import { IProjectStore } from 'types/projectStore';
import { mapCardImgProportion } from 'config/cardImgProportions';

export type CategoryType = CategoryFieldsFragment & {
  childCategories?: CategoryType[];
};

export class ProjectStore implements IProjectStore {
  readonly gettingStage: LoadingStageModel = new LoadingStageModel();
  private rootStore: IRootStore;
  private _project: ProjectInfoFragment | null = null;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeObservable<ProjectStore, '_project'>(this, {
      _project: observable,

      project: computed,

      changeProject: action
    });
  }

  get project(): ProjectInfoFragment | null {
    return this._project;
  }

  get currency(): string | null {
    return this.project?.currency || null;
  }

  get projectCardImgProportion(): ProjectCardImgProportion | null {
    return this._project?.cardImgProportion
      ? mapCardImgProportion[this._project.cardImgProportion]
      : null;
  }

  getCategoryInProject(catId: string): CategoryType | null {
    if (!this.project) {
      return null;
    }

    const queue: CategoryType[] = [...this.project.categories];

    while (queue.length > 0) {
      const category = queue.pop();
      if (!category) {
        continue;
      }
      if (category.id === catId) {
        return category;
      } else {
        queue.push(...(category.childCategories || []));
      }
    }

    return null;
  }

  changeProject(project: ProjectInfoFragment): void {
    this._project = project;
  }

  async load(): Promise<BaseResponse> {
    if (this.gettingStage.isLoading) {
      return {
        isError: true
      };
    }

    this.gettingStage.loading();

    const { data } = await this.rootStore.apolloClient.query<GetProjectQuery>({
      query: GetProjectDocument
    });

    if (!data.project) {
      this.gettingStage.error();
      return {
        isError: true
      };
    } else {
      this.changeProject(data.project);

      this.gettingStage.success();
      return {
        isError: false
      };
    }
  }
}
