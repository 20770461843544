import * as query from 'query-string';
import * as React from 'react';
import { useLocation } from 'react-router';
import { Button } from '@shopback/ui';

import Error from 'components/Error';

const PaymentError: React.FC = () => {
  const { search } = useLocation();
  const { bot_url } = query.parse(search, {
    arrayFormat: 'none'
  }) as { bot_url?: string | null };

  return (
    <Error
      title="Ошибка оплаты"
      description="Кажется, ссылка недействительна."
      withErrorIcon
      button={
        bot_url ? (
          <Button
            fullWidth
            key="error"
            href={bot_url}
            size="lg"
            appearance="primary"
          >
            Перейти в бота
          </Button>
        ) : undefined
      }
    />
  );
};

export default PaymentError;
