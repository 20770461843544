import * as React from 'react';
import { ProductsPageTemplate } from '@shopback/ui';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { AttachmentType, ProductType } from '@shopback/ui/lib/types';

import { useProjectStore, useRootStore, useUiStore } from 'store/globalCtx';
import { ROUTES } from 'config/routes';
import { ProductListStore } from 'pages/ProductList/store';
import ProductCartActions from 'components/ProductCartActions';

const ProductList: React.FC = () => {
  const rootStore = useRootStore();
  const cartStore = rootStore.cartStore;
  const uiStore = useUiStore();
  const navigate = useNavigate();
  const projectStore = useProjectStore();
  const project = projectStore.project;
  const [queryParams] = useSearchParams();
  const parentCategoryId = queryParams.get('parentCategoryId');
  const categoryId = queryParams.get('categoryId');
  const [store] = React.useState(
    () => new ProductListStore(rootStore, { categoryId, parentCategoryId })
  );

  React.useEffect(() => {
    store.category.change(categoryId);
  }, [categoryId]);

  React.useEffect(() => {
    store.parentCategory.change(parentCategoryId);
  }, [parentCategoryId]);

  React.useEffect(() => {
    Telegram.WebApp.expand();
    store.load({ initial: true });

    return () => {
      store.reset();
    };
  }, []);

  if (!project) {
    return null;
  }

  const categories = !parentCategoryId
    ? project.categories
    : projectStore.getCategoryInProject(parentCategoryId)?.childCategories ||
      [];

  const products: ProductType[] = store.products.items.map((product) => ({
    id: product.id,
    name: product.name,
    description: product.description || undefined,
    price: product.price,
    currency: project.currency || undefined,
    attachments: product.attachments
      .slice(0, 3)
      .reduce<AttachmentType[]>((acc, attachment) => {
        if (project.viewMode !== 'LARGE') {
          if (!attachment.smallImage.url) {
            return acc;
          }

          acc.push({
            src: attachment.smallImage.url,
            height: attachment.smallImage.height || undefined,
            width: attachment.smallImage.width || undefined
          });

          return acc;
        }

        if (!attachment.bigImage.url) {
          return acc;
        }

        acc.push({
          src: attachment.bigImage.url,
          height: attachment.bigImage.height || undefined,
          width: attachment.bigImage.width || undefined
        });

        return acc;
      }, []),
    onClick: () => navigate(ROUTES.product.make(product.id)),
    actionsRenderProps: () => <ProductCartActions product={product} />
  }));

  const projectViewModeChoice =
    project.viewMode === 'LARGE'
      ? 'large'
      : project.viewMode === 'SMALL'
      ? 'small'
      : 'medium';

  return (
    <ProductsPageTemplate
      withScrollBar={uiStore.needScrollBar}
      projectMainPageTitle={project.mainPageTitle || undefined}
      projectViewModeChoice={projectViewModeChoice}
      alignCards={project.alignCards}
      proportion={projectStore.projectCardImgProportion}
      currency={projectStore.currency || undefined}
      cartButton={
        !cartStore.isEmpty
          ? {
              onClick: () => navigate(ROUTES.cart),
              projectCartButtonTitle: project.cartButtonTitle || undefined
            }
          : undefined
      }
      categories={
        project.showCategory && !!categories.length
          ? {
              parentCategoryId: parentCategoryId || undefined,
              selectedId: store.category.value,
              onSelect: (categoryId) =>
                navigate(
                  ROUTES.products.make({
                    parentCategoryId: parentCategoryId || undefined,
                    categoryId: categoryId || undefined
                  })
                ),
              categories
            }
          : undefined
      }
      search={
        project.showSearch
          ? {
              value: store.search.value,
              onChange: store.search.change
            }
          : undefined
      }
      products={{
        list: products,
        onNext: () => store.load({ initial: false }),
        hasMore: store.products.hasMore.value,
        isLoading: store.products.loadingStage.isLoading,
        isInitialLoading: store.products.isInitialLoading.value,
        wasSearch: store.wasSearch.value
      }}
    />
  );
};

export default observer(ProductList);
