import { action, computed, makeObservable, observable, toJS } from 'mobx';

import {
  AddonOptionFragment,
  AddProductDocument,
  AddProductMutation,
  AddProductMutationVariables,
  BuyProductDocument,
  BuyProductMutation,
  BuyProductMutationVariables,
  GetOrderDocument,
  GetOrderQuery,
  ProjectProjectCartModeChoices
} from 'generated/graphql';
import { IRootStore } from 'types/rootStore';
import { CartItem } from 'models/cart';
import { BaseResponse } from 'types/meta';
import { ICartStore } from 'types/cartStore';
import { LoadingStageModel } from 'models/loadingStage';
import { ProductItem } from 'models/product';
import { SelectedAddonsMap } from 'models/product/types';
import { compareAddons } from 'models/product/utils';

export class CartStore implements ICartStore {
  items: Array<CartItem> = [];
  readonly loadingStageModel: LoadingStageModel = new LoadingStageModel();
  readonly rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      items: observable,
      orderSum: computed,
      isEmpty: computed,
      addToCart: action
    });
  }

  destroy() {
    this.items = [];
  }

  get orderSum(): number {
    return this.items.reduce((acc, cartItem) => acc + cartItem.sum, 0);
  }

  get isEmpty(): boolean {
    return !this.items.length;
  }

  findProduct(productId: string) {
    return this.items.find((i) => i.id === productId);
  }

  getProductCount(productId: string) {
    return this.items
      .filter((p) => p.id === productId)
      .reduce((acc, next) => acc + next.count, 0);
  }

  getProductIndex(productId: string, addonsMap: SelectedAddonsMap = {}) {
    return this.items.findIndex(
      (i) => i.id === productId && compareAddons(i.selectedAddons, addonsMap)
    );
  }

  findItem(productId: string, addonsMap: SelectedAddonsMap = {}) {
    return this.items.find(
      (item) =>
        item.id === productId && compareAddons(item.selectedAddons, addonsMap)
    );
  }

  addToCart = async (
    product: ProductItem,
    selectedAddons: SelectedAddonsMap = {}
  ) => {
    const project = this.rootStore.projectStore.project;

    if (!project) {
      return;
    }

    const cartItem = CartItem.fromProduct(
      {
        ...product,
        price: product.price,
        selectedAddons: toJS(selectedAddons),
        count: product.maxCount,
        countInCart: 1
      },
      this
    );

    const response = await this.addProduct(product, 1);

    if (response.isError) {
      return;
    }

    this.items.push(cartItem);

    if (project.cartMode === ProjectProjectCartModeChoices.Single) {
      this.buy();
    }
  };

  addProduct = async (
    product: ProductItem,
    count: number
  ): Promise<BaseResponse> => {
    const { data } = await this.rootStore.apolloClient.mutate<
      AddProductMutation,
      AddProductMutationVariables
    >({
      mutation: AddProductDocument,
      variables: {
        data: { productId: Number(product.id), count }
      }
    });

    return {
      isError: !!data?.addProduct?.errors
    };
  };

  loadProducts = async (): Promise<BaseResponse> => {
    if (this.loadingStageModel.isLoading) {
      return { isError: true };
    }

    this.loadingStageModel.loading();

    const { data } = await this.rootStore.apolloClient.query<GetOrderQuery>({
      query: GetOrderDocument
    });

    if (data.order?.orderProducts) {
      this.loadingStageModel.success();
      this.items = [];
      data.order.orderProducts.forEach((product) => {
        const selectedAddons = Object.keys(product.product.addons || {}).reduce(
          (acc, addonId) => {
            const addon = product.product.addons?.find(
              (addon) => addon.id === addonId
            );
            if (addon) {
              const parsedOptions =
                product.product.addons?.[Number(addonId)].addonOptions;
              const addonOptions = addon.addonOptions.filter(
                // @ts-ignore
                (option) => (parsedOptions || []).indexOf(option.id) > -1
              );

              acc[addonId] = {
                addon,
                addonOptions: addonOptions.reduce(
                  (acc, next) => ({
                    ...acc,
                    [next.id]: next
                  }),
                  {} as Record<string, AddonOptionFragment>
                )
              };
            }

            return acc;
          },
          {} as SelectedAddonsMap
        );

        this.items.push(
          CartItem.fromProduct(
            {
              ...product.product,
              selectedAddons,
              countInCart: product.count || 0
            },
            this
          )
        );
      });
    } else {
      this.loadingStageModel.error();
    }

    return {
      isError: !!data.order?.orderProducts
    };
  };

  incCartCount = async (
    product: ProductItem,
    addons: SelectedAddonsMap = {}
  ) => {
    let cartItem = this.findItem(product.id, addons);

    if (!cartItem && Object.keys(addons).length > 0) {
      return this.addToCart(product, addons);
    }

    if (!cartItem) {
      cartItem = this.findProduct(product.id);
    }

    if (!cartItem) {
      return;
    }

    this.addProduct(product, cartItem.count + 1);
    cartItem.inc();
  };

  decCartCount = (product: ProductItem, addons: SelectedAddonsMap = {}) => {
    let cartItem = this.findItem(product.id, addons);

    if (!cartItem) {
      cartItem = this.findProduct(product.id);
    }

    if (!cartItem) {
      return;
    }

    this.addProduct(product, cartItem.count - 1);

    if (cartItem.count === 1) {
      const index = this.getProductIndex(product.id, addons);
      this.items.splice(index, 1);
      return;
    }

    cartItem.dec();
  };

  isProductInCart(id: string): boolean {
    return !!this.findProduct(id);
  }

  buy = async (): Promise<BaseResponse> => {
    const project = this.rootStore.projectStore.project;

    if (!project) {
      return {
        isError: true
      };
    }

    if (project.cartMode === ProjectProjectCartModeChoices.Single) {
      this.items = [];
    }

    return this.closeWebApp();
  };

  private async closeWebApp(): Promise<BaseResponse> {
    if (!this.rootStore.modeData) {
      return {
        isError: true
      };
    }

    const { data } = await this.rootStore.apolloClient.mutate<
      BuyProductMutation,
      BuyProductMutationVariables
    >({
      mutation: BuyProductDocument,
      variables: {
        data: {
          blockId: this.rootStore.modeData.blockId,
          chatId: this.rootStore.modeData.chatId
        }
      }
    });

    const isError = !!data?.buyProduct?.errors;

    if (!isError) {
      Telegram.WebApp.disableClosingConfirmation();
      Telegram.WebApp.close();
    }

    return {
      isError
    };
  }
}
