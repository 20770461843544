import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { ILocalStorageHandler } from 'types/localStorage';
import { IApolloClientStore } from 'types/apolloClientStore';
import { IProjectStore } from 'types/projectStore';
import { AppModeData } from 'types/app';

export enum ProjectModes {
  carouselAutoplay = 'carouselAutoplay'
}

export type ProjectModesType = {
  [ProjectModes.carouselAutoplay]: boolean;
};

export interface IRootStore {
  readonly localStorageHandler: ILocalStorageHandler;
  readonly apolloClientStore: IApolloClientStore;
  readonly apolloClient: ApolloClient<NormalizedCacheObject>;
  readonly projectStore: IProjectStore;
  modeData: AppModeData | null;

  modes: ProjectModesType;
}

export const WITHOUT_AUTOPLAY_CAROUSEL_PROJECTS = [
  '54ff4fa4-3e51-487a-b04e-4f461337c38e',
  'e711308e-e9a5-4a93-818c-aa87f03334d0',
  '79d198f3-ed2a-4c11-a095-e2beef5132aa',
  '6f8a6a84-d681-449e-9472-1b35c3f5398e'
];
