import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CategoriesPageTemplate } from '@shopback/ui';
import { CategoryWithPhotoType } from '@shopback/ui/lib/types';

import { useProjectStore, useUiStore } from 'store/globalCtx';
import { CategoryType } from 'store/ProjectStore';
import { ProductCategoryChildCategoriesViewModeChoices } from 'generated/graphql';
import { CategoryIdParam, ROUTES } from 'config/routes';

const CategoriesList: React.FC = () => {
  const projectStore = useProjectStore();
  const uiStore = useUiStore();
  const navigate = useNavigate();
  const { project } = projectStore;
  const { categoryId } = useParams<{ categoryId: CategoryIdParam }>();

  const category = React.useMemo(() => {
    return !categoryId || categoryId === 'all'
      ? null
      : projectStore.getCategoryInProject(categoryId);
  }, [categoryId, projectStore]);

  React.useEffect(() => {
    Telegram.WebApp.expand();
  }, []);

  const categories = React.useMemo((): CategoryType[] => {
    if (!project) {
      return [];
    }

    if (!categoryId) {
      return [];
    }

    if (categoryId === 'all') {
      return project.categories;
    }

    return category ? category.childCategories || [] : [];
  }, [category, categoryId, project]);

  // TODO сделать отдельную модель на категорию и вынести туда
  const normalizedCategories = React.useMemo((): CategoryWithPhotoType[] => {
    return categories.map((cat) => ({
      ...cat,
      attachment:
        !!cat.attachment && !!cat.attachment.smallImage.url
          ? {
              src: cat.attachment.smallImage.url,
              height: cat.attachment.smallImage.height || undefined,
              width: cat.attachment.smallImage.width || undefined
            }
          : undefined
    }));
  }, [categories]);

  const handleCategoryClick = React.useCallback(
    (id: string) => {
      navigate(ROUTES.categories.make(id));
    },
    [navigate]
  );

  const redirectToProducts = React.useMemo((): boolean => {
    return (
      category?.childCategoriesViewMode ===
        ProductCategoryChildCategoriesViewModeChoices.Horizontal ||
      categories.length === 0
    );
  }, [category, categories.length]);

  React.useEffect(() => {
    if (redirectToProducts && categoryId) {
      navigate(ROUTES.products.make({ parentCategoryId: categoryId }), {
        replace: true
      });
    }
  }, [redirectToProducts, categoryId]);

  if (!project || redirectToProducts) {
    return null;
  }

  return (
    <CategoriesPageTemplate
      withScrollBar={uiStore.needScrollBar}
      parentCategoryName={category?.name}
      projectMainPageTitle={project.mainPageTitle || undefined}
      categories={normalizedCategories}
      onCategoryClick={handleCategoryClick}
      alignCards={project.alignCards}
      proportion={projectStore.projectCardImgProportion}
    />
  );
};

export default CategoriesList;
