import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { useHistoryLength } from 'utils/useHistoryLength';

export const useTGBackButton = () => {
  const navigate = useNavigate();
  const { isEmpty } = useHistoryLength();

  React.useEffect(() => {
    const backBtn = Telegram.WebApp.BackButton;

    if (!isEmpty) {
      backBtn.onClick(() => navigate(-1));
      backBtn.show();
    }

    return () => {
      backBtn.hide();
    };
  }, [isEmpty]);
};
