import { action, computed, makeObservable, observable } from 'mobx';

import { LoadingStage } from 'types/loadingStage';

export class LoadingStageModel {
  private _value: LoadingStage = LoadingStage.NOT_STARTED;

  constructor() {
    makeObservable<LoadingStageModel, '_value'>(this, {
      _value: observable,

      value: computed,
      isLoading: computed,
      successfullyLoaded: computed,
      isNotStarted: computed,

      loading: action,
      success: action,
      error: action,
      reset: action
    });
  }

  get value(): LoadingStage {
    return this._value;
  }

  get successfullyLoaded(): boolean {
    return this._value === LoadingStage.SUCCESS;
  }

  get isLoading(): boolean {
    return this._value === LoadingStage.LOADING;
  }

  get isNotStarted(): boolean {
    return this._value === LoadingStage.NOT_STARTED;
  }

  loading = (): void => {
    this._value = LoadingStage.LOADING;
  };

  success = (): void => {
    this._value = LoadingStage.SUCCESS;
  };

  error = (): void => {
    this._value = LoadingStage.ERROR;
  };

  reset = (): void => {
    this._value = LoadingStage.NOT_STARTED;
  };
}
