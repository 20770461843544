export type AppModeData = {
  channelId: string;
  chatId: string;
  blockId: string;
  webAppData: string;
  projectId: string;
};

export enum OpeningParameter {
  channelId = 'channel_id',
  chatId = 'chat_id',
  blockId = 'block_id',
  projectId = 'project'
}

export const openingParameters: OpeningParameter[] = [
  OpeningParameter.blockId,
  OpeningParameter.channelId,
  OpeningParameter.chatId,
  OpeningParameter.projectId
];
