import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProductPageTemplate } from '@shopback/ui';
import { observer } from 'mobx-react';
import { AttachmentType } from '@shopback/ui/lib/types';

import { useProjectStore, useRootStore, useUiStore } from 'store/globalCtx';
import { ROUTES } from 'config/routes';
import CartActions from 'pages/ProductPage/CartActions';
import { ModificatorType } from 'generated/graphql';
import { ProjectModes } from 'types/rootStore';

import { ProductPageStore } from './store';

const ProductPage: React.FC = () => {
  const { id } = useParams();
  const rootStore = useRootStore();
  const uiStore = useUiStore();
  const cartStore = rootStore.cartStore;
  const [store] = React.useState(() => new ProductPageStore(rootStore));

  React.useEffect(() => {
    Telegram.WebApp.expand();
    store.load(Number(id));
  }, [id, store]);

  const navigate = useNavigate();
  const projectStore = useProjectStore();
  const project = projectStore.project;

  const handleSelectOption = React.useCallback(
    async (data: ModificatorType) => {
      const response = await store.loadProductByMod(data);

      if (!response.isError) {
        navigate(ROUTES.product.make(response.data), {
          replace: true
        });
      }
    },
    []
  );

  if (!project) {
    return null;
  }

  const product = store.product.value;

  const attachments: AttachmentType[] = (product?.attachments || []).reduce<
    AttachmentType[]
  >((acc, attach) => {
    if (!attach.bigImage.url) {
      return acc;
    }

    acc.push({
      src: attach.bigImage.url,
      height: attach.bigImage.height || undefined,
      width: attach.bigImage.width || undefined
    });

    return acc;
  }, []);

  return (
    <ProductPageTemplate
      withScrollBar={uiStore.needScrollBar}
      isLoading={
        store.loadingStage.isLoading || store.loadingStage.isNotStarted
      }
      name={product?.name}
      attachments={attachments}
      autoplay={rootStore.modes[ProjectModes.carouselAutoplay]}
      description={product?.description || undefined}
      price={product?.price}
      currency={projectStore.currency || undefined}
      actionsRenderProps={() =>
        product ? <CartActions product={product} store={store} /> : null
      }
      // @ts-ignore TODO убрать когда бек изменить string на enum
      options={
        product?.productOptions?.options
          ? {
              list: product?.productOptions.options,
              onSelectOption: handleSelectOption
            }
          : undefined
      }
      cartButton={
        cartStore.isEmpty
          ? undefined
          : {
              onClick: () => navigate(ROUTES.cart),
              projectCartButtonTitle: project.cartButtonTitle || undefined
            }
      }
    />
  );
};

export default observer(ProductPage);
