import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ApolloProvider } from '@apollo/client';
import { CustomProvider } from 'rsuite';
import { matchPath, useLocation } from 'react-router';

import MainPage from 'pages/MainPage';
import ProductPage from 'pages/ProductPage';
import LoadingScreen from 'pages/LoadingScreen';
import Cart from 'pages/Cart';
import { useTGBackButton } from 'components/TGBackButton';
import ErrorPage from 'pages/ErrorPage';

import { useScrollTop } from './utils/useScrollTop';
import { ROUTES } from './config/routes';
import { GlobalCtx, useRootStore } from './store/globalCtx';

const App = () => {
  const rootStore = useRootStore();
  const location = useLocation();
  useScrollTop();
  useTGBackButton();

  React.useEffect(() => {
    Telegram.WebApp.expand();

    if (!matchPath(ROUTES.error.pattern, location.pathname)) {
      rootStore.init();
    }
  }, []);

  return (
    <CustomProvider>
      <ApolloProvider client={rootStore.apolloClient}>
        {rootStore.initStage.isLoading && <LoadingScreen />}
        {!rootStore.initStage.isLoading && (
          <GlobalCtx.Provider value={rootStore}>
            <Routes location={location}>
              <Route path={ROUTES.main.pattern} element={<MainPage />} />
              <Route path={ROUTES.cart} element={<Cart />} />
              <Route path={ROUTES.product.pattern} element={<ProductPage />} />
              <Route path={ROUTES.error.pattern} element={<ErrorPage />} />
            </Routes>
          </GlobalCtx.Provider>
        )}
      </ApolloProvider>
    </CustomProvider>
  );
};

export default observer(App);
