import * as React from 'react';

import { Container, Item, IconItem, Title } from './styles';
import ErrorIcon from './ErrorIcon';

type Props = {
  icon?: React.ReactNode;
  withErrorIcon?: boolean;
  title: string;
  description?: string;
  button?: React.ReactNode;
};

const Error: React.FC<Props> = ({
  icon,
  withErrorIcon,
  title,
  description,
  button
}: Props) => {
  return (
    <Container>
      {icon && <IconItem>{icon}</IconItem>}
      {!icon && withErrorIcon && (
        <IconItem>
          <ErrorIcon />
        </IconItem>
      )}
      <Title>{title}</Title>
      {description && <Item>{description}</Item>}
      {button && <Item>{button}</Item>}
    </Container>
  );
};

export default Error;
