import * as React from 'react';
import { useParams } from 'react-router';

import { Wrapper } from 'components/Wrapper';
import PaymentError from 'components/PaymentError';
import Error from 'components/Error';
import { ErrorPageType } from 'types/errorPage';

const mapErrorPageTypeToComponent: Record<ErrorPageType, React.ComponentType> =
  {
    [ErrorPageType.payment]: PaymentError
  };

const ErrorPage: React.FC = () => {
  const { type } = useParams<{ type: ErrorPageType | string }>();

  const Component = React.useMemo((): React.ComponentType | null => {
    return type && Object.values(ErrorPageType).includes(type as ErrorPageType)
      ? mapErrorPageTypeToComponent[type as ErrorPageType]
      : null;
  }, [type]);

  return (
    <Wrapper>
      {Component ? <Component /> : <Error withErrorIcon title="Ошибка" />}
    </Wrapper>
  );
};

export default React.memo(ErrorPage);
