import { ILocalStorageHandler } from 'types/localStorage';

export class LocalStorageHandler implements ILocalStorageHandler {
  readonly storage: Map<string, string>;
  private localStorageSupported: boolean;

  constructor() {
    this.storage = new Map<string, string>();
    this.localStorageSupported = !!window.localStorage;
  }

  setItem(key: string, value: string): void {
    if (this.localStorageSupported) {
      localStorage.setItem(key, value);
    } else {
      this.storage.set(key, value);
    }
  }

  getItem(key: string): string | null {
    if (this.localStorageSupported) {
      return localStorage.getItem(key);
    } else {
      return this.storage.get(key) || null;
    }
  }

  remove(key: string): void {
    if (this.localStorageSupported) {
      localStorage.removeItem(key);
    } else {
      this.storage.delete(key);
    }
  }
}
