import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';

export const initSentry = () => {
  Sentry.init({
    dsn: 'https://0f47eab6571447e08f779e7dc06ac4ea@sentry.pulse.kts.studio/242',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0
  });
};
