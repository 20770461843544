import { ProjectCardImgProportion } from '@shopback/ui';

import { ProjectProjectCardImgProportionChoices } from 'generated/graphql';

export const mapCardImgProportion: Record<
  ProjectProjectCardImgProportionChoices,
  ProjectCardImgProportion
> = {
  [ProjectProjectCardImgProportionChoices.SixteenNine]: 'sixteen_nine',
  [ProjectProjectCardImgProportionChoices.NineSixteen]: 'nine_sixteen',
  [ProjectProjectCardImgProportionChoices.FourFive]: 'four_five',
  [ProjectProjectCardImgProportionChoices.OneOne]: 'one_one'
};
