import { IReactionDisposer, reaction } from 'mobx';
import debounce from 'lodash.debounce';

import { ListModel } from 'models/list';
import { ProductItem } from 'models/product';
import { BaseResponse } from 'types/meta';
import { IRootStore } from 'types/rootStore';
import {
  GetProductListDocument,
  GetProductListQuery,
  GetProductListQueryVariables
} from 'generated/graphql';
import { ValueModel } from 'models/value';

const LIST_LIMIT = 10;

export class ProductListStore {
  readonly products: ListModel<ProductItem> = new ListModel<ProductItem>();
  private rootStore: IRootStore;
  readonly search: ValueModel = new ValueModel<string>('');
  readonly category: ValueModel<string | null>;
  readonly parentCategory: ValueModel<string | null>;
  private handleSearchChanging: IReactionDisposer;
  private handleCategoryChanging: IReactionDisposer;
  readonly wasSearch: ValueModel<boolean> = new ValueModel<boolean>(false);

  constructor(
    rootStore: IRootStore,
    {
      categoryId,
      parentCategoryId
    }: { categoryId: string | null; parentCategoryId: string | null }
  ) {
    this.rootStore = rootStore;

    this.category = new ValueModel(categoryId);
    this.parentCategory = new ValueModel(parentCategoryId);

    this.handleSearchChanging = reaction(
      () => this.search.value,
      this.loadDebounced
    );
    this.handleCategoryChanging = reaction(
      () => this.category.value,
      () => this.load({ initial: true })
    );
  }

  load = async ({
    initial = false,
    search = false
  }: {
    initial?: boolean;
    search?: boolean;
  }): Promise<BaseResponse> => {
    if (this.products.loadingStage.isLoading) {
      return {
        isError: true
      };
    }

    this.products.loadingStage.loading();
    this.products.isInitialLoading.change(initial);
    this.wasSearch.change(search);

    const { data } = await this.rootStore.apolloClient.query<
      GetProductListQuery,
      GetProductListQueryVariables
    >({
      query: GetProductListDocument,
      variables: {
        limit: LIST_LIMIT,
        offset: initial ? 0 : this.products.length,
        filters: {
          category: this.category.value || this.parentCategory.value,
          search: this.search.value
        }
      }
    });

    if (data.productList) {
      const keys: string[] = [];
      const entities: Record<string, ProductItem> = {};

      data.productList.products.forEach((product) => {
        const model = ProductItem.fromJson(
          product,
          this.rootStore.projectStore
        );

        keys.push(model.id);
        entities[model.id] = model;
      });

      this.products.addEntities({
        entities,
        keys,
        initial: initial || this.products.length === 0
      });

      this.products.hasMore.change(
        !(Number(data.productList.count) < LIST_LIMIT)
      );

      this.products.loadingStage.success();

      return {
        isError: false
      };
    } else {
      this.products.loadingStage.error();

      return {
        isError: true
      };
    }
  };

  private loadDebounced = debounce(
    () => this.load({ initial: true, search: true }),
    500
  );

  reset = () => {
    this.handleSearchChanging();
    this.handleCategoryChanging();
  };
}
