import styled from 'styled-components';
import { mixins } from '@shopback/ui';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  ${mixins.square('100px', '50%')}
  background-image: linear-gradient(349deg, #fa5853, #f46692 52%, #ffc444);
  display: flex;
  align-items: center;
  justify-content: center;
`;
