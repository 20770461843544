import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useProjectStore } from 'store/globalCtx';
import CategoriesList from 'pages/CategoriesList';
import { ROUTES } from 'config/routes';
import ProductList from 'pages/ProductList';
import { ProjectProjectCategoryViewModeChoices } from 'generated/graphql';

const MainPage: React.FC = () => {
  const project = useProjectStore().project;

  if (!project) {
    return null;
  }

  const mainUrl =
    project.categoryViewMode !== ProjectProjectCategoryViewModeChoices.Photo ||
    !project.showCategory
      ? ROUTES.products.make()
      : ROUTES.categories.make('all');

  return (
    <Routes>
      <Route path={ROUTES.categories.pattern} element={<CategoriesList />} />
      <Route path={ROUTES.products.index} element={<ProductList />} />
      <Route path="*" element={<Navigate to={mainUrl} replace />} />
    </Routes>
  );
};

export default MainPage;
