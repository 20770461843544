import { useLocation, useNavigationType } from 'react-router-dom';
import * as React from 'react';

export const useHistoryLength = (): { length: number; isEmpty: boolean } => {
  const navigationType = useNavigationType();
  const [length, setLength] = React.useState(0);
  const location = useLocation();
  const prevLocationKey = React.useRef<string>(location.key);

  React.useEffect(() => {
    if (prevLocationKey.current === location.key) {
      return;
    }

    switch (navigationType) {
      case 'POP': {
        setLength((c) => c - 1);
        break;
      }
      case 'PUSH': {
        setLength((c) => c + 1);
        break;
      }
    }
    prevLocationKey.current = location.key;
  }, [location, navigationType]);

  return { length, isEmpty: !(length > 0) };
};
