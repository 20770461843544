import * as React from 'react';
import { CountSelector as CountSelectorBase } from '@shopback/ui';

import { useProjectStore } from 'store/globalCtx';
import { ProjectProjectCartModeChoices } from 'generated/graphql';

type Props = {
  value: number;
  onInc(): void;
  onDec(): void;
  incDisabled?: boolean;
};

const CountSelector: React.FC<Props> = ({
  value,
  onInc,
  onDec,
  incDisabled
}: Props) => {
  const project = useProjectStore().project;

  if (!project) {
    return null;
  }

  return (
    <CountSelectorBase
      oneItemMode={project.cartMode === ProjectProjectCartModeChoices.OneItem}
      incDisabled={incDisabled}
      onInc={onInc}
      onDec={onDec}
      value={value}
    />
  );
};

export default CountSelector;
