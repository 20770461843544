import { createQueryString } from 'utils/createQueryString';

export type CategoryIdParam = string | 'all';

export const ROUTES = {
  main: {
    index: '/',
    pattern: '*'
  },
  cart: '/cart',

  products: {
    index: '/products',
    make: (params: { categoryId?: string; parentCategoryId?: string } = {}) =>
      `/products${createQueryString(params)}`
  },

  categories: {
    index: '/category',
    pattern: '/category/:categoryId',
    make: (id: CategoryIdParam) => `${ROUTES.categories.index}/${id}`
  },

  product: {
    index: '/product',
    pattern: '/product/:id',
    make: (id: number | string) => `${ROUTES.product.index}/${id}`
  },

  error: {
    pattern: '/error/:type'
  }
};
