import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { computed, makeObservable } from 'mobx';

import {
  IRootStore,
  ProjectModes,
  ProjectModesType,
  WITHOUT_AUTOPLAY_CAROUSEL_PROJECTS
} from 'types/rootStore';
import { LoadingStageModel } from 'models/loadingStage';
import { BaseResponse } from 'types/meta';
import { AppModeData, OpeningParameter } from 'types/app';
import { UiStore } from 'store/UiStore';

import { AuthStore } from './AuthStore';
import { LocalStorageHandler } from './LocalStorageHandler';
import { ApolloClientStore } from './ApolloClientStore';
import { ProjectStore } from './ProjectStore';
import { CartStore } from './CartStore';

export class RootStore implements IRootStore {
  readonly cartStore: CartStore = new CartStore(this);
  readonly authStore: AuthStore = new AuthStore(this);
  readonly uiStore: UiStore = new UiStore();
  readonly localStorageHandler: LocalStorageHandler = new LocalStorageHandler();
  readonly apolloClientStore: ApolloClientStore = new ApolloClientStore();
  readonly projectStore: ProjectStore = new ProjectStore(this);

  readonly initStage: LoadingStageModel = new LoadingStageModel();
  modeData: AppModeData | null = null;

  modes: ProjectModesType = {
    [ProjectModes.carouselAutoplay]: true
  };

  constructor() {
    makeObservable(this, {
      isAuthorize: computed,
      apolloClient: computed
    });
  }

  get isAuthorize(): boolean {
    return this.authStore.authStage.successfullyLoaded;
  }

  get apolloClient(): ApolloClient<NormalizedCacheObject> {
    return this.apolloClientStore.client;
  }

  async init(): Promise<BaseResponse> {
    if (this.initStage.isLoading) {
      return {
        isError: true
      };
    }

    this.initStage.loading();

    this.initApp();
    const authResponse = await this.authStore.authorize();
    if (authResponse.isError) {
      return {
        isError: true
      };
    }

    this.apolloClientStore.initializeClientLink(authResponse.data);
    this.cartStore.loadProducts();

    const projectResponse = await this.projectStore.load();

    if (projectResponse.isError) {
      this.initStage.error();

      return {
        isError: true
      };
    }

    // для красивого появления загрузочного экрана
    setTimeout(() => {
      this.initStage.success();
    }, 1000);

    return {
      isError: false
    };
  }

  private getParameter(param: OpeningParameter): string | null {
    const params = new URLSearchParams(window.location.search);

    const parameter =
      params.get(param) || this.localStorageHandler.getItem(param);

    if (parameter) {
      this.localStorageHandler.setItem(param, parameter);
    }

    return parameter;
  }

  private defineAppMode() {
    const channelId = import.meta.env.DEV
      ? import.meta.env.VITE_CHANNEL_ID
      : this.getParameter(OpeningParameter.channelId);
    const chatId = import.meta.env.DEV
      ? import.meta.env.VITE_CHAT_ID
      : this.getParameter(OpeningParameter.chatId);
    const blockId = import.meta.env.DEV
      ? import.meta.env.VITE_BLOCK_ID
      : this.getParameter(OpeningParameter.blockId);
    const projectId = import.meta.env.DEV
      ? import.meta.env.VITE_PROJECT_ID
      : this.getParameter(OpeningParameter.projectId);
    const webAppData = import.meta.env.DEV
      ? import.meta.env.VITE_WEB_APP_INIT_DATA
      : Telegram.WebApp.initData;

    if (!channelId || !chatId || !blockId || !webAppData || !projectId) {
      return;
    }

    this.modeData = {
      projectId,
      channelId,
      chatId,
      blockId,
      webAppData
    };

    this.modes[ProjectModes.carouselAutoplay] =
      WITHOUT_AUTOPLAY_CAROUSEL_PROJECTS.indexOf(projectId) < 0;
  }

  private initApp() {
    this.defineAppMode();

    Telegram.WebApp.ready();
    Telegram.WebApp.enableClosingConfirmation();
  }
}
