export const createQueryString = (
  params: Record<string, string | null | undefined>
): string => {
  const filteredObject = Object.keys(params).reduce<Record<string, string>>(
    (acc, param) => {
      const value = params[param];

      if (value !== undefined && value !== null) {
        acc[param] = value;
      }

      return acc;
    },
    {}
  );

  const urlSearchParams = new URLSearchParams(filteredObject);

  return `?${urlSearchParams.toString()}`;
};
