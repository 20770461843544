import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IconItem = styled.div`
  flex-shrink: 0;
  margin-bottom: 20px;
`;

export const Title = styled.strong`
  font-size: 40px;
`;

export const Item = styled.div`
  font-size: 16px;
  margin-top: 20px;
`;
