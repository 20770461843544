import { SelectedAddonsMap } from 'models/product/types';

export const arrayEquals = (a: Array<any>, b: Array<any>) => {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
};

export const compareAddons = (
  addons1: SelectedAddonsMap,
  addons2: SelectedAddonsMap
) => {
  const compareAddonIds = arrayEquals(
    Object.keys(addons1).sort(),
    Object.keys(addons2).sort()
  );
  if (!compareAddonIds) {
    return false;
  }

  const pairs = Object.keys(addons1)
    .sort()
    .map((addonId) => [addons1[addonId], addons2[addonId]]);

  for (const [addon1, addon2] of pairs) {
    if (
      !arrayEquals(
        Object.keys(addon1.addonOptions).sort(),
        Object.keys(addon2.addonOptions).sort()
      )
    ) {
      return false;
    }
  }

  return true;
};
