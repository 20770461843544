import {
  AddonFragment,
  ImageAttachmentFragment,
  ProductItemFragment,
  ProductOptionsFragment,
  SelectedOptionsFragment
} from 'generated/graphql';
import { IProjectStore } from 'types/projectStore';

export class ProductItem implements Omit<ProductItemFragment, 'count'> {
  readonly id: string;
  readonly name: string;
  readonly description?: string | null;
  private _price: number;
  readonly attachments: ImageAttachmentFragment[];
  readonly maxCount: number | null;
  readonly productOptions: ProductOptionsFragment;
  readonly selectedOptions: SelectedOptionsFragment;
  readonly productAddons?: AddonFragment[] | null;
  readonly categoryId: string | null;

  readonly projectStore: IProjectStore;

  constructor(
    {
      id,
      name,
      description,
      price,
      attachments,
      maxCount,
      productOptions,
      selectedOptions,
      addons,
      categoryId
    }: Omit<ProductItemFragment, 'count' | 'price'> & {
      maxCount: number | null;
      categoryId: string | null;
      price: number;
    },
    projectStore: IProjectStore
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this._price = price;
    this.attachments = attachments;
    this.maxCount = maxCount;
    this.productOptions = productOptions;
    this.productAddons = addons;
    this.selectedOptions = selectedOptions;
    this.categoryId = categoryId;

    this.projectStore = projectStore;
  }

  get hasRequiredAddons(): boolean {
    return Boolean(this.productAddons?.some((addon) => addon.isRequired));
  }

  get price(): number {
    return this._price;
  }

  get avatar(): string | null {
    if (!this.attachments.length) {
      return null;
    }

    return this.attachments[0].file;
  }

  get isFree(): boolean {
    return this.price <= 0;
  }

  static fromJson(
    data: ProductItemFragment,
    projectStore: IProjectStore
  ): ProductItem {
    return new ProductItem(
      {
        ...data,
        price: Number(data.price) || 0,
        maxCount: typeof data.count === 'number' ? data.count : null,
        categoryId: data.category?.id || null
      },
      projectStore
    );
  }
}
