import {
  ApolloClient,
  createHttpLink,
  DefaultOptions,
  InMemoryCache,
  NormalizedCacheObject
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { action, makeObservable, observable } from 'mobx';

const httpLink = createHttpLink({
  uri: '/graphql'
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
};

export class ApolloClientStore {
  client: ApolloClient<NormalizedCacheObject>;

  constructor() {
    makeObservable(this, {
      client: observable,

      initializeClientLink: action
    });

    const link = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers
        }
      };
    });

    this.client = new ApolloClient({
      link: link.concat(httpLink),
      cache: new InMemoryCache(),
      defaultOptions: defaultOptions
    });
  }

  initializeClientLink = (token: string): void => {
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          'x-token': token
        }
      };
    });

    this.client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
      defaultOptions: defaultOptions
    });
  };
}
